import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import ReplayIcon from "@material-ui/icons/Replay";
import {
    formatMessageWithValues, withModulesManager, withHistory, historyPush, journalize,
    Form, ProgressOrError
} from "@openimis/fe-core";
import { RIGHT_PAYROLL } from "../../constants";
import PayrollcycleDisplayPanel from "../Panels/PayrollcycleDisplayPanel";
import PayrollMasterPanel from "../Panels/PayrollMasterPanel";


import { fetchPayroll, fetchPayrollcycle , fetchPaymentDetails} from "../../actions";
import { payrollLabel } from "../../utils";

const styles = theme => ({
    page: theme.page,
});

const PAYROLL_PAYROLL_FORM_CONTRIBUTION_KEY = "payroll.PayrollForm"

class PayrollForm extends Component {

    state = {
        lockNew: false,
        reset: 0,
        paymentDetails: this._newPaymentDetails(),
        newPaymentDetails: true,
    }

    _newPaymentDetails() {
        let paymentDetails = {};
        paymentDetails.jsonExt = {};
        return paymentDetails;
    }

    componentDidMount() {
        document.title = formatMessageWithValues(this.props.intl, "insuree", "Insuree.title", { label: "" })
        if (!!this.props.payroll_uuid) {
            this.setState(
                (state, props) => ({ payroll_uuid: props.payroll_uuid }),
                e => this.props.fetchPayroll(
                    this.props.modulesManager,
                    this.props.payroll_uuid
                )
            )
        } else if (!!this.props.payrollcycle_uuid && (!this.props.payrollcycle || this.props.payrollcycle.uuid !== this.props.payrollcycle_uuid)) {
            this.props.fetchPayrollcycle(this.props.modulesManager, this.props.payrollcycle_uuid)
        } else if (!!this.props.payrollcycle_uuid) {
            let payroll = { ...this.state.payroll }
            payroll.payrollcycle = { ...this.props.payrollcycle }
            this.setState({ payroll })
        }
    }

    back = e => {
        const { modulesManager, history, payrollcycle_uuid, payroll_uuid } = this.props;
        if (payrollcycle_uuid) {
            historyPush(modulesManager,
                history,
                "payroll.route.payrollcycleOverview",
                [payrollcycle_uuid]
            );
        } else {
            historyPush(modulesManager,
                history,
                "payroll.route.payrolls"
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevState.payroll && prevState.payroll.insuree && prevState.payroll.insuree.chfId)
            !== (this.state.payroll && this.state.payroll.insuree && this.state.payroll.insuree.chfId)) {
            document.title = formatMessageWithValues(this.props.intl, "payroll", "Payroll.title", { label: payrollLabel(this.state.payroll) })
        }
        if (prevProps.fetchedPayroll !== this.props.fetchedPayroll && !!this.props.fetchedPayroll) {
            var payroll = this.props.payroll || {};
            payroll.ext = !!payroll.jsonExt ? JSON.parse(payroll.jsonExt) : {};
            this.setState(
                { payroll, payroll_uuid: payroll.uuid});
        } else if (prevProps.payroll_uuid && !this.props.payroll_uuid) {
            document.title = formatMessageWithValues(this.props.intl, "insuree", "Insuree.title", { label: payrollLabel(this.state.insuree) })
            this.setState({ insuree: this._newPaymentDetails(), newPaymentDetails: true, lockNew: false, payroll_uuid: null });
        } else if (prevProps.submittingMutation && !this.props.submittingMutation) {
            this.props.journalize(this.props.mutation);
            this.setState({ reset: this.state.reset + 1 });
        }
    }

    _add = () => {
        this.setState((state) => ({
            insuree: this._newPaymentDetails(),
            newPaymentDetails: true,
            lockNew: false,
            reset: state.reset + 1,
        }),
            e => {
                this.props.add();
                this.forceUpdate();
            }
        )
    }

    reload = () => {
        this.props.fetchPayrollPaymentDetails(
            this.props.modulesManager,
            this.state.payroll_uuid
        );
    }

    canSave = () => {
        if (!this.state.paymentDetails.receivedAmount) return false;
        if (!this.state.paymentDetails.transactionNumber) return false;
        if (!this.state.paymentDetails.pspUuid) return false;
        if (!this.state.paymentDetails.paymentStatus) return false;
        return true;
    }

    _save = (paymentDetails) => {
        this.setState(
            { lockNew: !paymentDetails.uuid }, // avoid duplicates
            e => this.props.save(paymentDetails))
    }

    onEditedChanged = paymentDetails => {
        this.setState({ paymentDetails, newPaymentDetails: false })
    }

    render() {
        const { rights,
            payroll_uuid, fetchingPayroll, fetchedPayroll, errorPayroll,
            payrollcycle, payrollcycle_uuid, fetchingPayrollcycle, fetchedPayrollcycle, errorPayrollcycle,
            readOnly = true,
            add, save,
        } = this.props;
        const { payroll } = this.state;
        if (!rights.includes(RIGHT_PAYROLL)) return null;
        let actions = [{
            doIt: this.reload,
            icon: <ReplayIcon />,
            onlyIfDirty: !readOnly
        }];
        return (
            <Fragment>
                <ProgressOrError progress={fetchingPayroll} error={errorPayroll} />
                <ProgressOrError progress={fetchingPayrollcycle} error={errorPayrollcycle} />
                {((!!fetchedPayroll && !!payroll && payroll.uuid === payroll_uuid) || !payroll_uuid) &&
                    ((!!fetchedPayrollcycle && !!payrollcycle && payrollcycle.uuid === payrollcycle_uuid) || !payrollcycle_uuid) &&
                    (
                        <Form
                            module="payroll"
                            title="payroll.title"
                            titleParams={{ label: payrollLabel(this.state.payroll) }}
                            // edited_id={paymentDetails_uuid}
                            edited={this.state.paymentDetails}
                            reset={this.state.reset}
                            back={this.back}
                            // add={!!add && !this.state.newPaymentDetails ? this._add : null}
                            readOnly={readOnly}
                            actions={actions}
                            HeadPanel={PayrollcycleDisplayPanel}
                            Panels={[PayrollMasterPanel]}
                            contributedPanelsKey={PAYROLL_PAYROLL_FORM_CONTRIBUTION_KEY}
                            payroll={this.state.payroll}
                            onEditedChanged={this.onEditedChanged}
                            canSave={this.canSave}
                            save={!!save ? this._save : null}
                        />
                    )}
            </Fragment>
        )
    }
}

const mapStateToProps = (state, props) => ({
    rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    fetchingPayroll: state.payroll.fetchingPayroll,
    errorPayroll: state.payroll.errorPayroll,
    fetchedPayroll: state.payroll.fetchedPayroll,
    payroll: state.payroll.payroll,
    fetchingPayrollcycle: state.payroll.fetchingPayrollcycle,
    errorPayrollcycle: state.payroll.errorPayrollcycle,
    fetchedPayrollcycle: state.payroll.fetchedPayrollcycle,
    payrollcycle: state.payroll.payrollcycle,
    paymentDetails: state.payroll.payrollPaymentDetails,
    submittingMutation: state.payroll.submittingMutation,
    mutation: state.payroll.mutation,
})

export default withHistory(withModulesManager(connect(mapStateToProps, { fetchPayroll, fetchPayrollcycle, fetchPaymentDetails, journalize })(
    injectIntl(withTheme(withStyles(styles)(PayrollForm))
    ))));