import messages_en from "./translations/en.json";
import ProgramsMainMenu from "./menu/ProgramsMainMenu";
import SchemesPage from "./pages/SchemesPage";
import SchemePage from "./pages/SchemePage";
import ProgramsOverviewPage from "./pages/ProgramsOverviewPage";
import schemeOverviewPage from "./pages/schemeOverviewPage";
import ProgramPage from "./pages/ProgramPage";
import SchemeStatusPicker from "./pickers/SchemeStatusPicker";
import SchemeTypePicker from "./pickers/SchemeTypePicker";
import FrequencyTypePicker from "./pickers/FrequencyTypePicker";
import insureeActivePicker from "./pickers/insureeActivePicker";
import AddProgramsPage from "./pages/AddProgramsPage";
import AddProgramsPageIns from "./pages/AddProgramsPageIns";
import AddSchemePage from "./pages/AddSchemePage";
import reducer from "./reducer";
import React from "react";
import { ListAlt, AccountBalanceOutlined, Keyboard } from "@material-ui/icons";
import { FormattedMessage } from "@openimis/fe-core";
import SchemePicker from "./pickers/SchemePicker";
import officerPickerSchemes from "./pickers/officerPickerSchemes";
import DropDownSchemesPicker from "./pickers/DropDownSchemesPicker";

import SchemeSearcher from "./components/SchemeSearcher";
import InsureeSchemeInsureePage from "./components/InsureeSchemeInsureePage";
import InsureeSchemeInsureeCode from "./components/InsureeSchemeInsureeCode";
import InsureeActivePage from "./pages/InsureeActivePage";
import PotentialBeneficiaryList from "./reports/PotentialBeneficaryList";
import PotentialBeneficiary from "./reports/PotentialBeneficiary";


const ROUTE_SCHEME_SCHEMES = "programs/schemes";
const ROUTE_SCHEME_SCHEME = "programs/scheme";
const ROUTE_SCHEME_SCHEME_OVERVIEW = "programs/schemeOverview";
const ROUTE_SCHEME_PROGRAMS_OVERVIEW = "programs/programsOverview";

const ROUTE_PROGRAMS_PROGRAM = "programs/program";
const ROUTE_INSUREE_SCHEME = "programs/insureescheme";

const ROUTE_SCHEME_ADD_SCHEMES = "programs/Addschemes";
const ROUTE_SCHEME_ADD_PROGRAMS = "programs/Addprograms";
const ROUTE_SCHEME_ADD_PROGRAMS_INS = "programs/AddprogramsIns";
// const ROUTE_PROGRAMS_PROGRAMS = "programs/programs";

const ROUTE_INSUREE_ACTIVE = "programs/insureeActive";


const DEFAULT_CONFIG = {
  "translations": [{ key: "en", messages: messages_en }],
  "reducers": [{ key: 'programs', reducer }],

  "reports": [
    {
      key: "potential_beneficiary_list",
      component: PotentialBeneficiaryList,
      isValid: (values) => values.name,
      getParams: (values) => ({
        name: values.name.name,
      }),
    },
    {
      key: "potential_beneficiary",
      component: PotentialBeneficiary,
      isValid: (values) => values.name,
      getParams: (values) => ({
        name: values.name.name,
      }),
    }
  ],

  "refs": [
    { key: "programs.route.schemes", ref: ROUTE_SCHEME_SCHEMES },
    { key: "programs.route.scheme", ref: ROUTE_SCHEME_SCHEME },
    { key: "programs.route.schemeOverview", ref: ROUTE_SCHEME_SCHEME_OVERVIEW },
    
    { key: "programs.route.programsOverview", ref: ROUTE_SCHEME_PROGRAMS_OVERVIEW },
    { key: "programs.route.program", ref: ROUTE_PROGRAMS_PROGRAM },
    { key: "programs.route.insureetoscheme", ref: ROUTE_INSUREE_SCHEME },
    { key: "programs.route.addinsureetoscheme", ref: ROUTE_SCHEME_SCHEME },
    { key: "programs.route.addprograms", ref: ROUTE_SCHEME_ADD_PROGRAMS },
    { key: "programs.route.addprogramsins", ref: ROUTE_SCHEME_ADD_PROGRAMS_INS },
    // { key: "programs.route.programs", ref: ROUTE_PROGRAMS_PROGRAMS },

    { key: "scheme.SchemeTypePicker", ref: SchemeTypePicker },
    { key: "scheme.SchemeStatusPicker", ref: SchemeStatusPicker },
    { key: "scheme.FrequencyTypePicker", ref: FrequencyTypePicker },
    { key: "scheme.InsureeActivePicker", ref: insureeActivePicker },
    { key: "scheme.SchemePicker", ref: SchemePicker },
    { key: "scheme.officerPickerSchemes", ref: officerPickerSchemes },
    { key: "scheme.DropDownSchemesPicker", ref: DropDownSchemesPicker },

    { key: "programs.route.programsFamily", ref: SchemeSearcher },

    { key: "programs.route.insureeActive", ref: ROUTE_INSUREE_ACTIVE },
  ],
  "core.Router": [
    { path: ROUTE_SCHEME_SCHEMES, component: SchemesPage },
    { path: ROUTE_SCHEME_SCHEME+ "/:scheme_uuid?", component: SchemePage },

    { path: ROUTE_SCHEME_PROGRAMS_OVERVIEW + "/:scheme_uuid", component: ProgramsOverviewPage },
    { path: ROUTE_SCHEME_SCHEME_OVERVIEW + "/:scheme_uuid", component: schemeOverviewPage },
    { path: ROUTE_PROGRAMS_PROGRAM, component: ProgramPage },
    { path: ROUTE_INSUREE_SCHEME + "/:scheme_uuid?", component: ProgramPage },

    { path: ROUTE_SCHEME_ADD_SCHEMES, component: AddSchemePage },
    { path: ROUTE_SCHEME_ADD_PROGRAMS, component: AddProgramsPage },
    { path: ROUTE_SCHEME_ADD_PROGRAMS_INS+ "/:insuree_uuid?", component: AddProgramsPageIns },
    // { path: ROUTE_PROGRAMS_PROGRAMS, component: ProgramsPage },

    { path: ROUTE_INSUREE_ACTIVE+ "/:programs_uuid?", component: InsureeActivePage },
  ],

  "insuree.MainMenu": [
    {
      text: <FormattedMessage module="programs" id="menu.programs" />,
      icon: <ListAlt />,
      route: "/" + ROUTE_SCHEME_SCHEMES,
    },
  ],
  // "admin.MainMenu": [
  //   {
  //     text: <FormattedMessage module="Dia_Form" id="menu.diaf" />,
  //     icon: <AccountBalanceOutlined />,
  //     route: "/" + ROUTE_MY_ENTITIES,
  //   },
  // ],
  
  "core.MainMenu": [ProgramsMainMenu],
  // "insuree.InsureeForm": [InsureeSchemeInsureePage],
  
  
}

export const ProgramsModule = (cfg) => {
  return { ...DEFAULT_CONFIG, ...cfg };
}

