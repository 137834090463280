import {
  graphql, formatQuery, formatPageQuery, formatPageQueryWithCount,
  formatJsonField, decodeId, formatMutation, formatGQLString
} from "@openimis/fe-core";

export function fetchSchemeSummaries(mm, filters) {
  var projections = [
    "id", "uuid", "validityFrom", "validityTo",
    "code", "name", "amount", "schemeType", "status", "startDate", "endDate"]
  const payload = formatPageQueryWithCount("schemes",
    filters,
    projections
  );
  return graphql(payload, 'SCHEME_SCHEMES');
}

export function deleteScheme(mm, scheme_uuid, scheme, clientMutationLabel) {

  let mutation = formatMutation("deleteScheme", `${!!scheme_uuid ? `uuid: "${scheme_uuid}",` : ""} `, clientMutationLabel);

  scheme.clientMutationId = mutation.clientMutationId;

  var requestedDateTime = new Date();

  return graphql(

    mutation.payload,

    ['PROGRAMS_MUTATION_REQ', 'PROGRAMS_DELETE_SCHEME_RESP', 'PROGRAMS_MUTATION_ERR'],

    {

      clientMutationId: mutation.clientMutationId,

      clientMutationLabel,

      requestedDateTime,

      schemeUuid: scheme_uuid,

    }

  )

}


export function fetchSchemes(prms) {
  const payload = formatPageQueryWithCount(
    "schemes",
    prms,
    ["name", "uuid", "code", "schemeType", "amount", "startDate", "endDate", "status"]
  );
  return graphql(payload, 'SCHEME_SCHEMES');
}

const SCHEME_FULL_PROJECTION = mm => [
  "id", "uuid", "name", "code", "schemeType", "amount", "startDate", "endDate", "status",
  "validityFrom", "validityTo",
];

export function fetchSchemeFull(mm, uuid) {
  let payload = formatPageQuery("schemes",
    [`uuid:"${uuid}"`],
    SCHEME_FULL_PROJECTION(mm),
    "clientMutationId");
  return graphql(payload, 'SCHEME_SCHEMES');
}

//This function fetches a scheme by uuid provider....
export function fetchScheme(mm, uuid) {

  let filters = [
    `schemeUuid: "${uuid}"`
  ]

  let projections = [
    "id", "uuid", "name", "code", "schemeType",
    "amount", "startDate", "endDate", "status",
    "validityFrom", "validityTo",
  ]
  const payload = formatPageQuery(`schemeDetails`,
    filters,
    projections
  );
  return graphql(payload, 'SCHEME_SCHEME');
}

const FAMILY_HEAD_PROJECTION = "headInsuree{id,uuid,chfId,lastName,otherNames,email,phone,dob,gender{code}}";

const FAMILY_FULL_PROJECTION = mm => [
  "id", "uuid", "poverty", "confirmationNo", "confirmationType{code}", "familyType{code}", "address",
  "validityFrom", "validityTo",
  FAMILY_HEAD_PROJECTION,
  "location" + mm.getProjection("location.Location.FlatProjection"),
  "clientMutationId"
];

const INSUREE_FULL_PROJECTION = mm => [
  "id", "uuid", "chfId", "lastName", "otherNames", "dob", "age",
  "validityFrom", "validityTo",
  `family{${FAMILY_FULL_PROJECTION(mm).join(",")}}`,
  `photo{id,uuid,date,folder,filename,officerId,photo}`,
  "gender{code}",
  "education{id}",
  "profession{id}",
  "marital",
  "cardIssued",
  "currentVillage" + mm.getProjection("location.Location.FlatProjection"),
  "currentAddress",
  "typeOfId{code}", "passport",
  "relationship{id}",
  "head",
  "email",
  "phone",
];

const PRORAMS_FULL_PROJECTION = mm => [
  INSUREE_FULL_PROJECTION(mm), SCHEME_FULL_PROJECTION(mm),
  "validityFrom", "validityTo",
  "clientMutationId"
];

export function fetchPrograms(mm, schUuid) {
  let filters = []
  if (!!schUuid) {
    filters.push(`schemeUuid: "${schUuid}"`)
  }
  let projections = [
    "id", "uuid", "scheme{id, uuid, name, code, status, schemeType}", "insuree{id, uuid, chfId, lastName, otherNames}",
    "validityFrom", "validityTo",
  ]
  const payload = formatPageQuery("schemeProgramsDetails",
    filters,
    projections
  );
  return graphql(payload, 'SCHEME_PRORAMS_OVERVIEW');
}

export function savePrograms(programs, clientMutationLabel) {
  console.log("Musa Programs ", programs);
  let programGQL = `
      insureeUuid: "${programs["insuree"]["uuid"]}"
      schemeUuid: "${programs["scheme"]["uuid"]}"
      status: "${programs.status}"
      expAmount: "${programs.expAmount}"
  `
  let mutation = formatMutation("createPrograms", programGQL, clientMutationLabel);
  console.log(mutation);
  var requestedDateTime = new Date();
  return graphql(
    mutation.payload,
    "PROGRAMS_CREATE_PROGRAMS_RESP", "PROGRAMS_MUTATION_ERR",
    {
      clientMutationId: mutation.clientMutationId,
      clientMutationLabel,
      requestedDateTime
    }
  )
}

export function fetchProgramsSummaries(mm, filters) {
  var projections = [
    "id", "uuid", "validityFrom", "validityTo",
    "scheme{id, uuid, validityFrom, validityTo, code, name, amount, schemeType, status, startDate, endDate}",
    "insuree{id, uuid, validityFrom, validityTo, chfId, lasnName, otherNames}"]
  const payload = formatPageQueryWithCount("programs",
    filters,
    projections
  );
  return graphql(payload, 'PROGRAM_PROGRAMS');
}

export function saveEntity(entity, clientMutationLabel) {
  let entityGQL = `
    name: "${entity.name}"
    code: "${entity.code}"
    schemeType: "${entity.schemeType}"
    startDate: "${entity.startDate}"
    status: "${entity.status}"
    amount: "${entity.amount}"      
  `
  let mutation = formatMutation("createScheme", entityGQL, clientMutationLabel);
  var requestedDateTime = new Date();
  return graphql(
    mutation.payload,
    "MY_MODULE_CREATE_ENTITYS",
    {
      clientMutationId: mutation.clientMutationId,
      clientMutationLabel,
      requestedDateTime
    }
  )
}


// PROGRAMS FOR INSUREE *********************************************************************************
export function fetchSchemeforInsuree(mm, insureeUuid) {
  let filters = []
  if (!!insureeUuid) {
    filters.push(`insureeUuid: "${insureeUuid}"`)
  }
  let projections = [
    "id", "uuid", "scheme{name, code, schemeType, startDate}", "insuree{chfId, lastName, otherNames}",
    "validityFrom", "validityTo",
  ]
  const payload = formatPageQuery("schemesByInsureeDetails",
    filters,
    projections
  );
  return graphql(payload, 'SCHEME_INSUREE_OVERVIEW');
}

export function fetchSchemeforInsureeCode(mm, chfId) {
  let filters = []
  if (!!chfId) {
    filters.push(`chfId: "${chfId}"`)
  }
  let projections = [
    "id", "uuid", "scheme{name, code, schemeType, startDate}", "insuree{chfId, lastName, otherNames}",
    "validityFrom", "validityTo",
  ]
  const payload = formatPageQuery("schemesByInsureeCode",
    filters,
    projections
  );
  return graphql(payload, 'SCHEME_INSUREE_OVERVIEW');
}

export function fetchSchemesForPicker(mm, filters) {
  let payload = formatPageQueryWithCount("schemes", filters, SCHEME_FULL_PROJECTION(mm));
  return graphql(payload, "SCHEME_SCHEMES");
}

export function saveDiaf(diaf, clientMutationLabel) {
  let DiafGQL = ` 
  reasonForApplyingWelfareBenefit: "${diaf.reasonForApplyingWelfareBenefit}"
  dependencyChildren: "${diaf.dependencyChildren}"
  dependencyRelative: "${diaf.dependencyRelative}"
  beneficiarySupport: "${diaf.beneficiarySupport}"
  nameOfBeneficiary: "${diaf.nameOfBeneficiary}"
  addressOfBeneficiary: "${diaf.addressOfBeneficiary}"
  description: "${diaf.description}"
  receiveMoneyFromAnySource: "${diaf.receiveMoneyFromAnySource}"
  receiveAnySupportAtAll: "${diaf.receiveAnySupportAtAll}"
  ifYesSpecify: "${diaf.ifYesSpecify}"
  employed: "${diaf.employed}"
  weeklyWage: ${diaf.weeklyWage}
  employmentForYou: "${diaf.employmentForYou}"
  ifNoWhy: "${diaf.ifNoWhy}"
  cashAssets: "${diaf.cashAssets}"
  savingsAccountNumber: ${diaf.savingsAccountNumber}
  DebtsOwing: "${diaf.DebtsOwing}"
  interestInProperty: "${diaf.interestInProperty}"

  fridge: "${diaf.fridge}"
  freezer: "${diaf.freezer}"
  washingMarchine: "${diaf.washingMarchine}"
  fryingPan: "${diaf.fryingPan}"
  electricOven: "${diaf.electricOven}"
  salariesWages: ${diaf.salariesWages}
  pensionPayment: ${diaf.pensionPayment}
  childAllowance: ${diaf.childAllowance}
  localMarketSales: ${diaf.localMarketSales}
  mfnPrivateProduceShipment: ${diaf.mfnPrivateProduceShipment}
  handicraftSales: ${diaf.handicraftSales}
  overseasRemittances: ${diaf.overseasRemittances}
  othersIncome: ${diaf.othersIncome}
  householdExpenses: ${diaf.householdExpenses}
  eps: ${diaf.eps}
  telecom: ${diaf.telecom}
  bcn: ${diaf.bcn}
  petrol: ${diaf.petrol}
  houseLoan: ${diaf.houseLoan}
  loans: ${diaf.loans}
  westpac: ${diaf.westpac}
  psis: ${diaf.psis}
  ndb: ${diaf.ndb}
  savingsLoan: ${diaf.savingsLoan}
  othersExpenditure: ${diaf.othersExpenditure}
  totalIncome: ${diaf.totalIncome}
  totalExpenditure: ${diaf.totalExpenditure}
  difference: ${diaf.difference}
  shortfallDisposableIncome: ${diaf.shortfallDisposableIncome}
  insureeUuid: "${diaf["insuree"]["uuid"]}"
  `
  let mutation = formatMutation("createDiaf", DiafGQL, clientMutationLabel);
  var requestedDateTime = new Date();
  return graphql(
    mutation.payload,
    "DIAF_CREATE_DIAF",
    {
      clientMutationId: mutation.clientMutationId,
      clientMutationLabel,
      requestedDateTime
    }
  )
}

export function formatSchemeGQL(scheme) {
  return `
    ${scheme.uuid !== undefined && scheme.uuid !== null ? `uuid: "${scheme.uuid}"` : ""}
    ${!!scheme.name ? `name: "${formatGQLString(scheme.name)}"` : ""}
    ${!!scheme.startDate ? `startDate: "${formatGQLString(scheme.startDate)}"` : ""}
    ${!!scheme.endDate ? `endDate: "${formatGQLString(scheme.endDate)}"` : ""}
    ${!!scheme.code ? `code: "${formatGQLString(scheme.code)}"` : ""}
    ${!!scheme.status ? `status: "${formatGQLString(scheme.status)}"` : ""}
    ${!!scheme.schemeType ? `schemeType: "${formatGQLString(scheme.schemeType)}"` : ""}
    ${!!scheme.amount ? `amount: "${formatGQLString(scheme.amount)}"` : ""}
    ${!!scheme.closeDate ? `closeDate: "${formatGQLString(scheme.closeDate)}"` : ""}
    ${!!scheme.freqType ? `freqType: "${formatGQLString(scheme.freqType)}"` : ""}
    ${!!scheme.frequency ? `frequency: "${formatGQLString(scheme.frequency)}"` : ""}
  `;
}

export function createScheme(scheme, clientMutationLabel) {
  let mutation = formatMutation("createScheme", formatSchemeGQL(scheme), clientMutationLabel);
  var requestedDateTime = new Date();
  return graphql(mutation.payload, ["SCHEME_MUTATION_REQ", "SCHEME_CREATE_SCHEME_RESP", "SCHEME_MUTATION_ERR"], {
    clientMutationId: mutation.clientMutationId,
    clientMutationLabel,
    requestedDateTime,

  });

}

export function updateScheme(scheme, clientMutationLabel) {
  let mutation = formatMutation("updateScheme", formatSchemeGQL(scheme), clientMutationLabel);
  var requestedDateTime = new Date();
  return graphql(mutation.payload, ["SCHEME_MUTATION_REQ", "SCHEME_UPDATE_SCHEME_RESP", "SCHEME_MUTATION_ERR"], {
    clientMutationId: mutation.clientMutationId,
    clientMutationLabel,
    requestedDateTime,
    schemeUuid: scheme.uuid,
  });
}

export function formatProgramsGQL(programs) {
  return `
    ${programs.uuid !== undefined && programs.uuid !== null ? `uuid: "${programs.uuid}"` : ""}
    ${!!programs.scheme && !!programs.scheme.id ? `schemeUuid: "${programs.scheme.uuid}"` : ""}
    ${!!programs.insuree && !!programs.insuree.id ? `insureeUuid: "${programs.insuree.uuid}"` : ""}
    ${!!programs.status ? `status: "${formatGQLString(programs.status)}"` : ""}
    ${!!programs.expAmount ? `expAmount: "${formatGQLString(programs.expAmount)}"` : ""}
  `;
}

export function createPrograms(programs, clientMutationLabel) {
  let mutation = formatMutation("createPrograms", formatProgramsGQL(programs), clientMutationLabel);
  var requestedDateTime = new Date();
  return graphql(mutation.payload, ["PROGRAMS_MUTATION_REQ", "SCHEME_CREATE_PROGRAMS_RESP", "PROGRAMS_MUTATION_ERR"], {
    clientMutationId: mutation.clientMutationId,
    clientMutationLabel,
    requestedDateTime,

  });

}

// export function formatProgramsGQL(programs) {
//   return `
//     ${programs.uuid !== undefined && programs.uuid !== null ? `uuid: "${programs.uuid}"` : ""}
//     ${!!programs.scheme && !!programs.scheme.id ? `schemeUuid: "${programs.scheme.uuid}"` : ""}
//     ${!!programs.insuree && !!programs.insuree.id ? `insureeUuid: "${programs.insuree.uuid}"` : ""}
//     ${!!programs.status ? `status: "${formatGQLString(programs.status)}"` : ""}
//     ${!!programs.expAmount ? `expAmount: "${formatGQLString(programs.expAmount)}"` : ""}
//   `
// }

// export function createPrograms(programs, clientMutationLabel) {
//   console.log("##### Programs #####", programs);
//   let mutation = formatMutation("createPrograms", formatProgramsGQL(programs), clientMutationLabel);
//   var requestedDateTime = new Date();
//   return graphql(mutation.payload, ["PROGRAMS_MUTATION_REQ", "SCHEME_CREATE_PROGRAMS_RESP", "PROGRAMS_MUTATION_ERR"], {
//     clientMutationId: mutation.clientMutationId,
//     clientMutationLabel,
//     requestedDateTime,

//   });

// }

export function updatePrograms(programs, clientMutationLabel) {
  let mutation = formatMutation("updatePrograms", formatProgramsGQL(programs), clientMutationLabel);
  var requestedDateTime = new Date();
  return graphql(
    mutation.payload,
    ["PROGRAMS_MUTATION_REQ", "SCHEME_UPDATE_PROGRAMS_RESP", "PROGRAMS_MUTATION_ERR"],
    {
      clientMutationId: mutation.clientMutationId,
      clientMutationLabel,
      requestedDateTime,
      programsUuid: programs.uuid,
    }
  )
}