import React, { Component } from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { YESORNO } from "../constants";

class YesOrNoPicker extends Component {

    render() {
        const { readOnly = false, required = false } = this.props;
        
        return <ConstantBasedPicker
            module="niue"
            constants={YESORNO}
            readOnly={readOnly}
            {...this.props}
        />
    }
}

export default YesOrNoPicker;