import React, { Component, Fragment } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Divider } from "@material-ui/core";
import {
    FormattedMessage, PublishedComponent, FormPanel,
    TextInput, Contributions, withModulesManager
} from "@openimis/fe-core";

const styles = theme => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%"
    },
});

const PROGRAMS_SCHEME_CONTRIBUTION_KEY = "programs.Scheme"
const PROGRAMS_SCHEME_PANELS_CONTRIBUTION_KEY = "programs.Scheme.panels"

class SchemeMasterPanel extends FormPanel {

    constructor(props) {
        super(props);
        this.codeMaxLength = props.modulesManager.getConf("fe-programs", "schemeForm.codeMaxLength", 15);
    }

    render() {
        const {
            intl, classes, edited,
            title = "Scheme.title", titleParams = { label: "" },
            readOnly = true,
            actions
        } = this.props;
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container className={classes.tableTitle}>
                            <Grid item xs={3} className={classes.tableTitle}>
                                <Typography>
                                    <FormattedMessage module="scheme" id={title} values={titleParams} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container className={classes.item}>
                            <Grid item xs={3} className={classes.item}>
                                <TextInput
							        module="scheme" 
                                    label="SchemeForm.code"
							        value={edited.code}
							        required={true}
                                    onChange={v => this.updateAttribute("code", v)}
                                    inputProps={{
                                        "maxLength": this.codeMaxLength,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} className={classes.item}>
                                <TextInput
							        module="scheme" 
                                    label="SchemeForm.name"
							        value={edited.name}
							        required={true}
                                    onChange={v => this.updateAttribute("name", v)}
                                />
                            </Grid>
                            <Grid item xs={3} className={classes.item}>
                                <PublishedComponent
                                    pubRef="scheme.SchemeTypePicker"
                                    value={edited.schemeType}
                                    required={true}
                                    onChange={v => this.updateAttribute("schemeType", v)}
                                />
                            </Grid>
                            <Grid item xs={3} className={classes.item}>
                                <TextInput
							        module="scheme" 
                                    label="schemeForm.amount"
							        value={edited.amount}
							        required={false}
                                    onChange={v => this.updateAttribute("amount", v)}
						        />
                            </Grid>
                            <Grid item xs={4} className={classes.item}>
                                <PublishedComponent
                                    pubRef="scheme.SchemeStatusPicker"
                                    value={edited.status}
                                    required={true}
                                    onChange={v => this.updateAttribute("status", v)}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.item}>
                                <PublishedComponent 
							        pubRef="core.DatePicker"
							        label="schemeForm.startDate"
							        value={edited.startDate}
							        required={true}
                                    onChange={v => this.updateAttribute("startDate", v)}
						        />
                            </Grid>
                            <Grid item xs={4} className={classes.item}>
                                <PublishedComponent 
							        pubRef="core.DatePicker" 
							        label="schemeForm.endDate"
							        value={edited.endDate}
							        required={true}
                                    onChange={v => this.updateAttribute("endDate", v)}
						        />
                            </Grid>
                            <Contributions {...this.props} updateAttribute={this.updateAttribute} contributionKey={PROGRAMS_SCHEME_CONTRIBUTION_KEY} />
                        </Grid>
                    </Paper>
                    <Contributions {...this.props} updateAttribute={this.updateAttribute} contributionKey={PROGRAMS_SCHEME_PANELS_CONTRIBUTION_KEY} />
                </Grid>
            </Grid>
        );
    }
}

export default withModulesManager(withTheme(
    withStyles(styles)(SchemeMasterPanel)
));