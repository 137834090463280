import {
    parseData, pageInfo, formatServerError, formatGraphQLError,
    dispatchMutationReq, dispatchMutationResp, dispatchMutationErr,
} from '@openimis/fe-core';

function reducer(
    state = {
        fetchingSchemes: false,
        errorSchemes: null,
        fetchedSchemes: false,
        schemes: [],
        schemesPageInfo: { totalCount: 0 },

        fetchingScheme: false,
        errorScheme: null,
        fetchedScheme: false,
        scheme: null,
        schemePageInfo: { totalCount: 0 },

        fetchingPrograms: false,
        errorPrograms: null,
        fetchedPrograms: false,
        programs: [],
        programsPageInfo: { totalCount: 0 },

        submittingMutation: false,
        mutation: {},
    },
    action,
) {
    switch (action.type) {
        case 'SCHEME_SCHEMES_REQ':
            return {
                ...state,
                fetchingSchemes: true,
                fetchedSchemes: false,
                schemes: [],
                schemesPageInfo: { totalCount: 0 },
                errorSchemes: null,
            };
        case 'SCHEME_SCHEMES_RESP':
            return {
                ...state,
                fetchingSchemes: false,
                fetchedSchemes: true,
                schemes: parseData(action.payload.data.schemes),
                schemesPageInfo: pageInfo(action.payload.data.schemes),
                errorSchemes: formatGraphQLError(action.payload)
            };
        case 'SCHEME_SCHEMES_ERR':
            return {
                ...state,
                fetching: false,
                error: formatServerError(action.payload)
            };


        case 'SCHEME_SCHEME_REQ':

            return {
                ...state,
                fetchingScheme: true,
                fetchedScheme: false,
                scheme: null,
                errorScheme: null,
            };

        case 'SCHEME_SCHEME_RESP':
            var sch = parseData(action.payload.data.schemeDetails);
            return {

                ...state,
                fetchingScheme: false,
                fetchedScheme: true,
                scheme: (!!sch && sch.length > 0) ? sch[0] : null,
                errorScheme: formatGraphQLError(action.payload)
            };


        case 'PROGRAM_PROGRAMS_REQ':
            return {
                ...state,
                fetchingPrograms: true,
                fetchedPrograms: false,
                programs: [],
                programsPageInfo: { totalCount: 0 },
                errorPrograms: null,
            };

        case 'PROGRAM_PROGRAMS_RESP':
            return {
                ...state,
                fetchingPrograms: false,
                fetchedPrograms: true,
                programs: parseData(action.payload.data.programs),
                programsPageInfo: pageInfo(action.payload.data.programs),
                errorPrograms: formatGraphQLError(action.payload)
            };
        case 'PROGRAM_PROGRAMS_ERR':
            return {
                ...state,
                fetching: false,
                error: formatServerError(action.payload)
            };


        case 'SCHEME_PRORAMS_OVERVIEW_REQ':

            return {
                ...state,
                fetchingPrograms: true,
                fetchedPrograms: false,
                programs: null,
                programsPageInfo: { totalCount: 0 },
                errorPrograms: null,

            };

        case 'SCHEME_PRORAMS_OVERVIEW_RESP':

            return {
                ...state,
                fetchingPrograms: false,
                fetchedPrograms: true,
                programs: parseData(action.payload.data.schemeProgramsDetails),
                programsPageInfo: pageInfo(action.payload.data.schemeProgramsDetails),
                errorPrograms: formatGraphQLError(action.payload),
            };

        case 'SCHEME_PRORAMS_OVERVIEW_ERR':

            return {
                ...state,
                fetchingPrograms: false,
                errorPrograms: formatServerError(action.payload),
            };

        case 'SCHEME_INSUREE_OVERVIEW_REQ':

            return {
                ...state,
                fetchingPrograms: true,
                fetchedPrograms: false,
                programs: null,
                programsPageInfo: { totalCount: 0 },
                errorPrograms: null,

            };

        case 'SCHEME_INSUREE_OVERVIEW_RESP':

            return {
                ...state,
                fetchingPrograms: false,
                fetchedPrograms: true,
                programs: parseData(action.payload.data.schemesByInsureeDetails),
                programsPageInfo: pageInfo(action.payload.data.schemesByInsureeDetails),
                errorPrograms: formatGraphQLError(action.payload),
            };

        case 'SCHEME_INSUREE_OVERVIEW_ERR':

            return {
                ...state,
                fetchingPrograms: false,
                errorPrograms: formatServerError(action.payload),
            };




        case 'PROGRAMS_PRORAMS_OVERVIEW_REQ':
            return {
                ...state,
                fetchingPrograms: true,
                fetchedPrograms: false,
                programs: null,
                programsPageInfo: { totalCount: 0 },
                errorPrograms: null,
            };
        case 'PROGRAMS_PRORAMS_OVERVIEW_RESP':
            var programs = parseData(action.payload.data.schemeProgramsDetails);
            return {
                ...state,
                fetchingPrograms: false,
                fetchedPrograms: true,
                programs: (!!programs && programs.length > 0) ? programs[0] : null,
                errorPrograms: formatGraphQLError(action.payload)
            };
        case 'PROGRAMS_PRORAMS_OVERVIEW_ERR':
            return {
                ...state,
                fetchingPrograms: false,
                errorPrograms: formatServerError(action.payload)
            };
        case 'SCHEME_INSUREE_SCHEMES_REQ':
            return {
                ...state,
                fetchingPrograms: true,
                fetchedPrograms: false,
                programs: null,
                errorPrograms: null,
            };
        case 'SCHEME_INSUREE_SCHEMES_RESP':
            return {
                ...state,
                fetchingPrograms: false,
                fetchedPrograms: true,
                programs: parseData(action.payload.data.schemesByFamilyDetails),
                programsPageInfo: pageInfo(action.payload.data.schemesByFamilyDetails),
                errorPrograms: formatGraphQLError(action.payload)
            };
        case 'SCHEME_INSUREE_SCHEMES_ERR':
            return {
                ...state,
                fetchingPrograms: false,
                errorPrograms: formatServerError(action.payload),
            };
        case 'PROGRAMS_CREATE_SCHEME_RESP':
            return dispatchMutationResp(state, "createScheme", action);
        case 'PROGRAMS_UPDATE_SCHEME_RESP':
            return dispatchMutationResp(state, "updateScheme", action);
        case 'PROGRAMS_DELETE_SCHEME_RESP':
            return dispatchMutationResp(state, "deleteScheme", action);
        case 'PROGRAMS_CREATE_PROGRAMS_RESP':
            return dispatchMutationResp(state, "createPrograms", action);
        case 'PROGRAMS_UPDATE_PROGRAMS_RESP':
            return dispatchMutationResp(state, "updatePrograms", action);
        case 'PROGRAMS_DELETE_PROGRAMS_RESP':
            return dispatchMutationResp(state, "deletePrograms", action);


        case 'MY_MODULE_CREATE_ENTITY_REQ':
            return dispatchMutationReq(state, action)
        case 'MY_MODULE_CREATE_ENTITY_ERR':
            return dispatchMutationErr(state, action);
        case 'MY_MODULE_CREATE_ENTITY_RESP':
            return dispatchMutationResp(state, "createScheme", action);
        case 'SCHEME_DELETE_SCHEMES_RESP':
            return dispatchMutationResp(state, "deleteScheme", action);

        case "SCHEME_MUTATION_REQ":
            return dispatchMutationReq(state, action);
        case "SCHEME_MUTATION_ERR":
            return dispatchMutationErr(state, action);
        case "SCHEME_CREATE_SCHEME_RESP":
            return dispatchMutationResp(state, "createScheme", action);
        case "SCHEME_UPDATE_SCHEME_RESP":
            return dispatchMutationResp(state, "updateScheme", action);
        case "PROGRAMS_MUTATION_REQ":
            return dispatchMutationReq(state, action);
        case "PROGRAMS_MUTATION_ERR":
            return dispatchMutationErr(state, action);
        case "SCHEME_CREATE_PROGRAMS_RESP":
            return dispatchMutationResp(state, "createPrograms", action);
        case "SCHEME_UPDATE_PROGRAMS_RESP":
            return dispatchMutationResp(state, "updatePrograms", action);
        default:
            return state;
    }
}

export default reducer;
