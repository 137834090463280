import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {
    formatMessageWithValues, withModulesManager, withHistory, historyPush,
} from "@openimis/fe-core";
import PayrollcycleForm from "../components/Forms/PayrollcycleForm";
import { createPayrollcycle, updatePayrollcycle } from "../actions";
import { RIGHT_PAYROLLCYCLE_ADD, RIGHT_PAYROLLCYCLE_EDIT } from "../constants";
import { PayrollcycleLabel } from "../utils";

const styles = theme => ({
    page: theme.page,
});

class PayrollcyclePage extends Component {

    add = () => {
        historyPush(this.props.modulesManager, this.props.history, "payroll.route.payrollcycle")
    }

    save = (payrollcycle) => {
        if (!payrollcycle.uuid) {
            this.props.createPayrollcycle(
                this.props.modulesManager,
                payrollcycle,
                formatMessageWithValues( 
                    this.props.intl,
                    "payroll",
                    "createPayrollcycle.mutationLabel",
                    { label: PayrollcycleLabel(payrollcycle) }
                )
            );
        } else {
            this.props.updatePayrollcycle(
                this.props.modulesManager,
                payrollcycle,
                formatMessageWithValues(
                    this.props.intl,
                    "payroll",
                    "updatePayrollcycle.mutationLabel",
                    { label: PayrollcycleLabel(payrollcycle) }
                )
            );

        }
    }

    render() {
        const { classes, modulesManager, history, rights, payrollcycle_uuid, overview } = this.props;
        if (!rights.includes(RIGHT_PAYROLLCYCLE_EDIT)) return null;

        return (
            <div className={classes.page}>
                <PayrollcycleForm
                    overview={overview}
                    payrollcycle_uuid={payrollcycle_uuid}
                    back={e => historyPush(modulesManager, history, "payroll.route.payrollcycles")}
                    add={rights.includes(RIGHT_PAYROLLCYCLE_ADD) ? this.add : null}
                    save={rights.includes(RIGHT_PAYROLLCYCLE_EDIT) ? this.save : null}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    payrollcycle_uuid: props.match.params.payrollcycle_uuid,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ createPayrollcycle, updatePayrollcycle }, dispatch);
};

export default withHistory(withModulesManager(connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withTheme(withStyles(styles)(PayrollcyclePage))
    ))));