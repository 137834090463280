import React, { Component } from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { VILLAGE } from "../constants";

class VillagePicker extends Component {

    render() {
        const { readOnly = false, required = false, withNull = true, nullLabel = null } = this.props;
        return <ConstantBasedPicker
            module="payroll"
            readOnly={readOnly}
            constants={VILLAGE}
            {...this.props}
        />
    }
}

export default VillagePicker;