import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {
    formatMessageWithValues, withModulesManager, withHistory, historyPush,
} from "@openimis/fe-core";
import SchemeForm from "../components/SchemeForm";
import { createScheme, updateScheme } from "../actions";
import { RIGHT_SCHEME_ADD, RIGHT_SCHEME_EDIT } from "../constants";

const styles = theme => ({
    page: theme.page,
});

class SchemePage extends Component {

    add = () => {
        historyPush(this.props.modulesManager, this.props.history, "programs.route.scheme")
    }

    save = (scheme) => {
        if (!scheme.uuid) {
            this.props.createScheme(
                this.props.modulesManager,
                scheme,
                formatMessageWithValues(
                    this.props.intl,
                    "scheme",
                    "createScheme.mutationLabel",
                    { label: !!scheme.code ? scheme.code : "" }
                )
            );
        } else {
            this.props.updateScheme(
                this.props.modulesManager,
                scheme,
                formatMessageWithValues(
                    this.props.intl,
                    "scheme",
                    "updateScheme.mutationLabel",
                    { label: !!scheme.code ? scheme.code : "" }
                )
            );

        }
    }

    render() {
        const { classes, modulesManager, history, rights, scheme_uuid, overview } = this.props;
        if (!rights.includes(RIGHT_SCHEME_EDIT)) return null;
        return (
            <div className={classes.page}>
                <SchemeForm
                    overview={overview}
                    scheme_uuid={scheme_uuid}
                    back={e => historyPush(modulesManager, history, "programs.route.schemes")}
                    add={rights.includes(RIGHT_SCHEME_ADD) ? this.add : null}
                    save={rights.includes(RIGHT_SCHEME_EDIT) ? this.save : null}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    scheme_uuid: props.match.params.scheme_uuid,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ createScheme, updateScheme }, dispatch);
};

export default withHistory(withModulesManager(connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withTheme(withStyles(styles)(SchemePage))
    ))));