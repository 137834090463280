import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {
    formatMessageWithValues, withModulesManager, withHistory, historyPush,
} from "@openimis/fe-core";
import ProgramsForm from "../components/ProgramsForm";
import { createPrograms, updatePrograms } from "../actions";
import { RIGHT_PROGRAMS_ADD, RIGHT_PROGRAMS_EDIT } from "../constants";

const styles = theme => ({
    page: theme.page,
});

class ProgramPage extends Component {

    add = () => {
        historyPush(this.props.modulesManager, this.props.history, "programs.route.program")
    }

    save = (programs) => {
        if (!programs.uuid) {
            this.props.createPrograms(
                this.props.modulesManager,
                programs,
                formatMessageWithValues(
                    this.props.intl,
                    "programs",
                    "createPrograms.mutationLabel",
                    { label: !!scheme.code ? insuree.chfId : "" }
                )
            );
        } else {
            this.props.updatePrograms(
                this.props.modulesManager,
                programs,
                formatMessageWithValues(
                    this.props.intl,
                    "programs",
                    "updatePrograms.mutationLabel",
                    { label: !!scheme.code ? insuree.chfId : "" }
                )
            );

        }
    }

    render() {
        const { classes, modulesManager, history, rights, programs_uuid } = this.props;
        if (!rights.includes(RIGHT_PROGRAMS_EDIT)) return null;
        return (
            <div className={classes.page}>
                <ProgramsForm
                    programs_uuid={programs_uuid !== '_NEW_' ? programs_uuid :  null}
                    back={e => historyPush(modulesManager, history, "programs.route.programs")}
                    add={rights.includes(RIGHT_PROGRAMS_ADD) ? this.add : null}
                    save={rights.includes(RIGHT_PROGRAMS_EDIT) ? this.save : null}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    programs_uuid: props.match.params.programs_uuid,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ createPrograms, updatePrograms }, dispatch);
};

export default withHistory(withModulesManager(connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withTheme(withStyles(styles)(ProgramPage))
    ))));