import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import { bindActionCreators } from "redux";
import { historyPush, formatMessageWithValues, withModulesManager, withHistory } from "@openimis/fe-core";
import { ProgressOrError, Table } from "@openimis/fe-core";
import { Fab } from "@material-ui/core";
import { fetchPrograms } from "../actions";

const styles = theme => ({
    page: theme.page,
    fab: theme.fab

});


class ProgramsList extends Component {

    state = {
        edited: null,
        page: 0,
        pageSize: 5,
        afterCursor: null,
        beforeCursor: null,
    }

    constructor(props) {
        super(props);
        this.rowsPerPageOptions = props.modulesManager.getConf("fe-programs", "programsFilter.rowsPerPageOptions", [10, 20, 50, 100]);
        this.defaultPageSize = props.modulesManager.getConf("fe-programs", "programsFilter.defaultPageSize", 10);
    }

      //Define some parameters and pass it while fetching payroll from actions
   query = () =>{
    let prams = [];
    prams.push(`first: ${this.state.pageSize}`)
    if(!!this.state.afterCursor){
     prams.push(`after: "${this.state.afterCursor}"`)
    }
    if(!!this.state.beforeCursor){
     prams.push(`before: "${this.state.beforeCursor}"`)
    }
     prams.push(`orderBy: ["scheme"]`);
    this.props.fetchPrograms(prams,this.props.edited_id);
    }

    onChangeRowsPerPage = (cnt) =>{
        this.setState(
            {pageSize: cnt,
            page: 0,
            afterCursor: null,
            beforeCursor: null,
            },
            e => this.query()
        )
    }

    componentDidMount() {
        this.setState({ orderBy: null }, e => this.onChangeRowsPerPage(this.defaultPageSize))
    }

    schemeChanged = (prevProps) => (!prevProps.scheme && !!this.props.scheme) ||
        (
            !!prevProps.scheme &&
            !!this.props.scheme &&
            (prevProps.scheme.uuid == null || prevProps.scheme.uuid !== this.props.scheme.uuid)
        )

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.schemeChanged(prevProps)) {
            this.query();
            this.setState({ reload: false })
        }
    }

    queryPrms = () => {
        let prms = [];
        if (!!this.state.orderBy) {
            prms.push(`orderBy: "${this.state.orderBy}"`)
        }
        if (!!this.props.scheme && !!this.props.scheme.uuid) {
            prms.push(`uuid:"${this.props.scheme.uuid}"`);
            return prms;
        }
        return null;
    }

    // onDoubleClick = (i, newTab = false) => {
    //     historyPush(this.props.modulesManager, this.props.history, "programs.route.insureeActive", [i.uuid], newTab)
    // }


    //This function is trigged when the row per page droupdown change which sets the pageSize and recall the query
 

    //This function is trigged on next and previous page change
    onChangePage = (page, nbr) => {
       
        if (nbr > this.state.page) {
            this.setState((state, props) => ({
                page: this.state.page + 1,
                beforeCursor: null,
                afterCursor: this.props.programsPageInfo.endCursor,
            }),
                e => this.query(uuid)
            )

        } else if (nbr < this.state.page) {
            this.setState((state, props) => ({
                page: this.state.page - 1,
                beforeCursor: this.props.programsPageInfo.startCursor,
                afterCursor: null,
            }),
                e => this.query(uuid)
            )

        }
    }

    render() {
        const { intl, classes, edited_id, fetchingPrograms, errorPrograms, programs, programsPageInfo, rights } = this.props;

        let headers = [
            "programs.programsSummaries.i.chfId",
            "programs.programsSummaries.i.lastName",
            "programs.programsSummaries.i.otherNames",
            "programs.programsSummaries.s.code",
            "programs.programsSummaries.s.name",

        ]


        let itemFormatters = [
            e => e.insuree.chfId,
            e => e.insuree.lastName,
            e => e.insuree.otherNames,
            e => e.scheme.code,
            e => e.scheme.name,

        ]


        return (
            <div className={classes.page} >

                < ProgressOrError progress={fetchingPrograms} error={errorPrograms} />

                <Table
                    module="programs"
                    fetch={this.props.fetchPrograms}
                    header={formatMessageWithValues(intl, "programs", "programs.table", { count: programsPageInfo.totalCount })}
                    headers={headers}
                    itemFormatters={itemFormatters}
                    items={programs}
                    withPagination={true}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    count={programsPageInfo.totalCount}
                    onChangePage={this.onChangePage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                    rowsPerPageOptions={this.rowsPerPageOptions}
                    defaultPageSize={this.defaultPageSize}
                    // onDoubleClick={e => this.onDoubleClick(e)}
                    rights={this.rights}
                />

            </div>
        )
    }
}


const mapStateToProps = state => ({
    // rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    fetchingPrograms: state.programs.fetchingPrograms,
    errorPrograms: state.programs.errorPrograms,
    fetchedPrograms: state.programs.fetchedPrograms,
    programs: state.programs.programs,
    programsPageInfo: state.programs.programsPageInfo,
});

const mapDispatchToProps = dispatch => {
    
    return bindActionCreators({ fetchPrograms, }, dispatch);
};


export default withHistory(withModulesManager(connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withTheme(withStyles(styles)(ProgramsList))
    ))));