import React, { Component, Fragment } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from 'react-intl';
import _ from "lodash";
import { payrollLabel, PayrollcycleLabel } from "../../utils";
import { Checkbox, Paper, IconButton, Grid, Divider, Typography, Tooltip } from "@material-ui/core";
import {
    Search as SearchIcon,
    Add as AddIcon,
    PersonAdd as AddExistingIcon,
    CheckCircle as ApproveIcon,
    Delete as DeleteIcon,
    Clear as RemoveIcon,
} from '@material-ui/icons';
import {
    formatMessage, formatMessageWithValues,
    withModulesManager, formatDateFromISO, historyPush, withTooltip,
    FormattedMessage,
    formatSorter, sort,
    coreAlert,
    Table, PagedDataHandler, PublishedComponent, ProgressOrError
} from "@openimis/fe-core";
 import { fetchPayrollcyclePayrolls, selectPayroll, deletePayroll, approvePayroll, paidPayroll} from "../../actions";
 import { RIGHT_PAYROLL_APPROVE, RIGHT_PAYROLL_DELETE } from "../../constants";

const styles = theme => ({
    paper: theme.paper.paper,
    paperHeader: theme.paper.header,
    paperHeaderAction: theme.paper.action,
    tableTitle: theme.table.title,
    approveButton: {
        float: "right",
        position: "relative",
        top: "37px",
        right: "105px",
        z_index: "3000"
    },
});

class PayrollcyclePayrollOverview extends PagedDataHandler {

    state = {
        enquiryOpen: false,
        reset: 0,
        confirmedAction: null,
        removePayroll: null,
        canGenerateAction: null,
        checkedCanGenerate: false,
    }

    constructor(props) {
        super(props);
        this.rowsPerPageOptions = props.modulesManager.getConf("fe-payroll", "PayrollcyclePayrollOverview.rowsPerPageOptions", [100, 500, 1000]);
        this.defaultPageSize = props.modulesManager.getConf("fe-payroll", "PayrollcyclePayrollOverview.defaultPageSize", 100);
    }

    componentDidMount() {
        this.setState({ orderBy: null }, e => this.onChangeRowsPerPage(this.defaultPageSize))
    }

    payrollcycleChanged = (prevProps) => (!prevProps.payrollcycle && !!this.props.payrollcycle) ||
        (
            !!prevProps.payrollcycle &&
            !!this.props.payrollcycle &&
            (prevProps.payrollcycle.uuid == null || prevProps.payrollcycle.uuid !== this.props.payrollcycle.uuid)
        )

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.payrollcycleChanged(prevProps)) {
            this.query();
            this.setState({ reload: false })
        }
    }

    queryPrms = () => {
        let prms = [];
        if (!!this.state.orderBy) {
            prms.push(`orderBy: "${this.state.orderBy}"`)
        }
        if (!!this.props.payrollcycle && !!this.props.payrollcycle.uuid) {
            prms.push(`payrollcycleUuid:"${this.props.payrollcycle.uuid}"`);
            return prms;
        }
        return null;
    }

    onDoubleClick = (i, newTab = false) => {
        historyPush(this.props.modulesManager, this.props.history, "payroll.route.payroll", [i.uuid, this.props.payrollcycle.uuid], newTab)
    }

    onChangeSelection = (i) => {
        this.props.selectPayroll(i[0] || null)
    }

    confirmApproveAllPayroll = () => {
        let confirmedAction = () => {
            this.props.approvePayroll(
                this.props.modulesManager,
                this.props.payrollcycle.uuid,
                null,
                true,
                formatMessageWithValues(this.props.intl, "payroll", "approvePayroll.mutationLabel", { label: PayrollcycleLabel(this.props.payrollcycle) }),
            );
        }
        this.props.onActionToConfirm(
            formatMessageWithValues(this.props.intl, "payroll", "approvePayrollDialog.title", { label: PayrollcycleLabel(this.props.payrollcycle) }),
            formatMessageWithValues(this.props.intl, "payroll", "approvePayrollDialog.message",
                {
                    label: PayrollcycleLabel(this.props.payrollcycle),
                }),
            confirmedAction)
    }

    approveAllPayrollAction = () => (
        <div className={this.props.classes.approveButton}>
            <Tooltip title={formatMessage(this.props.intl, "payroll", "payrollcycleApprovePayroll.tooltip")}>
                <IconButton onClick={e => this.confirmApproveAllPayroll()}><ApproveIcon />Approve All</IconButton>
            </Tooltip>
            
        </div>
    )

    headers = [
        "payroll.scheme_name",
        "payroll.expectedamount",
        "payroll.insuree.chfId",
        "payroll.insuree_name",
        "payroll.approval_status",
        "payroll.payment_status",
        "payroll.transaction_number",
    ];

    adornedChfId = (i) => (
        <Fragment>
            <IconButton size="small" onClick={e => !i.clientMutationId && this.setState({ enquiryOpen: true, chfid: i.chfId })}><SearchIcon /></IconButton>
            {i.insuree.chfId}
        </Fragment>
    )
    
    confirmApprovePayroll = (i) => {
        let confirmedAction = () => {
            this.props.approvePayroll(
                this.props.modulesManager,
                this.props.payrollcycle.uuid,
                i,
                false,
                formatMessageWithValues(this.props.intl, "payroll", "approvePayroll.mutationLabel", { label: payrollLabel(i) }),
            );
        }
        this.props.onActionToConfirm(
            formatMessageWithValues(this.props.intl, "payroll", "approvePayrollDialog.title", { label: payrollLabel(i) }),
            formatMessageWithValues(this.props.intl, "payroll", "approvePayrollDialog.message",
                {
                    label: payrollLabel(i),
                }),
            confirmedAction)
    }

    approvePayrollAction = (i) => (
        <Tooltip title={formatMessage(this.props.intl, "payroll", "payrollcycleApprovePayroll.tooltip")}>
            <IconButton onClick={e => this.confirmApprovePayroll(i)}><ApproveIcon /></IconButton>
        </Tooltip>
    )

    confirmPaidPayroll = (i) => {
        let confirmedAction = () => {
            this.props.paidPayroll(
                this.props.modulesManager,
                this.props.payrollcycle.uuid,
                i,
                false,
                formatMessageWithValues(this.props.intl, "payroll", "paidPayroll.mutationLabel", { label: payrollLabel(i) }),
            );
        }
        this.props.onActionToConfirm(
            formatMessageWithValues(this.props.intl, "payroll", "paidPayrollDialog.title", { label: payrollLabel(i) }),
            formatMessageWithValues(this.props.intl, "payroll", "paidPayrollDialog.message",
                {
                    label: payrollLabel(i),
                }),
            confirmedAction)
    }

    paidPayrollAction = (i) => (
        <Tooltip title={formatMessage(this.props.intl, "payroll", "payrollcyclePaidPayroll.tooltip")}>
            <IconButton onClick={e => this.confirmPaidPayroll(i)}><ApproveIcon /></IconButton>
        </Tooltip>
    )
    
    confirmDeletePayroll = (i) => {
        let confirmedAction = () => {
            this.props.deletePayroll(
                this.props.modulesManager,
                this.props.payrollcycle.uuid,
                i,
                formatMessageWithValues(this.props.intl, "payroll", "deletePayroll.mutationLabel", { label: payrollLabel(i) }),
            );
        }
        this.props.onActionToConfirm(
            formatMessageWithValues(this.props.intl, "payroll", "deletePayrollDialog.title", { label: payrollLabel(i) }),
            formatMessageWithValues(this.props.intl, "payroll", "deletePayrollDialog.message",
                {
                    label: payrollLabel(i),
                }),
            confirmedAction)
    }

    deletePayrollAction = (i) => (
        <Tooltip title={formatMessage(this.props.intl, "payroll", "payrollcycleDeletePayroll.tooltip")}>
            <IconButton onClick={e => this.confirmDeletePayroll(i)}><DeleteIcon /></IconButton>
        </Tooltip>
    )

    formatters = [
        i => i.scheme.name,
        i => i.expectedamount,
        i => this.adornedChfId(i),
        i => i.insuree.otherNames + ' ' + i.insuree.lastName,
        i => formatMessage(this.props.intl, "payroll", "isApproved."+i.isApproved),
        i => formatMessage(this.props.intl, "payroll", "isPaid."+i.paymentStatus),
        i => i.transactionNumber,
        i => !!this.props.readOnly || !this.props.rights.includes(RIGHT_PAYROLL_APPROVE) || !!i.clientMutationId ? null : this.approvePayrollAction(i),
        //i => i.paymentStatus

    ];

    
    render() {
        const { intl, classes, pageInfo, payrollcycle, payrolls, fetchingPayrolls, errorPayrolls, readOnly,
            
        } = this.props;
        return (
            <Paper className={classes.paper}>
                {this.approveAllPayrollAction()}
                <Table
                    module="payroll"
                    headers={this.headers}
                    headerActions={this.headerActions}
                    itemFormatters={this.formatters}
                    items={(!!payrollcycle && payrolls) || []}
                    fetching={fetchingPayrolls}
                    error={errorPayrolls}
                    //onDoubleClick={this.onDoubleClick}
                    withSelection={"single"}
                    onChangeSelection={this.onChangeSelection}
                    withPagination={true}
                    rowsPerPageOptions={this.rowsPerPageOptions}
                    defaultPageSize={this.defaultPageSize}
                    page={this.currentPage()}
                    pageSize={this.currentPageSize()}
                    count={pageInfo.totalCount}
                    onChangePage={this.onChangePage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                    rowLocked={this.rowLocked}
                />
            </Paper>
        );
    }
}

const mapStateToProps = state => ({
    rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    alert: !!state.core ? state.core.alert : null,
    payrollcycle: state.payroll.payrollcycle,
    fetchingPayrolls: state.payroll.fetchingCyclePayrolls,
    fetchedPayrolls: state.payroll.fetchedCyclePayrolls,
    payrolls: state.payroll.cyclePayrolls,
    pageInfo: state.payroll.cyclePayrollsPageInfo,
    errorPayrolls: state.payroll.errorCyclePayrolls,
    submittingMutation: state.payroll.submittingMutation,
    mutation: state.payroll.mutation,
});

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fetch: fetchPayrollcyclePayrolls,
        selectPayroll, deletePayroll, paidPayroll, approvePayroll,
        coreAlert,
    }, dispatch);
};


export default withModulesManager(injectIntl(withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PayrollcyclePayrollOverview)))));