import React, { Component } from "react";
import { connect } from "react-redux";
import { Edit as EditIcon } from "@material-ui/icons";
import { historyPush, withModulesManager, withHistory } from "@openimis/fe-core";
import ProgramPage from "./ProgramPage";


class ProgramsOverviewPage extends Component {
    render() {
        const { history, modulesManager, scheme_uuid } = this.props;
        var actions = [{
            doIt: e => historyPush(modulesManager, history, "programs.route.program", [scheme_uuid]),
            icon: <EditIcon />,
            onlyIfDirty: false
        }]
        return <ProgramPage {...this.props} readOnly={true} overview={true} actions={actions} />
    }
}

const mapStateToProps = (state, props) => ({
    scheme_uuid: props.match.params.scheme_uuid,
})

export default withHistory(withModulesManager(connect(mapStateToProps)(ProgramsOverviewPage)));