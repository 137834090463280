import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import { RotateRight, SupervisedUserCircle, Assignment, AddBox, DoneOutline, Visibility, ImportExport, Payment, BarChart} from "@material-ui/icons";
import { formatMessage ,MainMenuContribution, withModulesManager } from "@openimis/fe-core";
// import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

//define payroll management menu component
class PayrollMainMenu extends Component {
    render() {
      const { intl, rights , modulesManager} = this.props;
      let entries = [];
      //push add payroll cycles submenu to payroll managenent menu items
      entries.push({
        text: formatMessage(intl,"payroll","menu.addPayrollcycles"),
        icon: <AddBox />,
        route: "/" + modulesManager.getRef("payroll.route.payrollcycle"),
      });
      //push payroll cycles submenu to payroll managenent menu items
      entries.push({
        text: formatMessage(intl,"payroll","menu.payrollcycles"),
        icon: <RotateRight />,
        route: "/payroll/payrollcycles"
      });
      //push payrolls submenu to payroll managenent menu items
      // entries.push({
      //   text: formatMessage(intl,"payroll","menu.payrolls"),
      //   icon: <SupervisedUserCircle/>,
      //   route: "/payroll/payrolls"
      // });
      // entries.push({
      //   text: formatMessage(intl,"payroll","menu.approvepayrolls"),
      //   icon: <DoneOutline/>,
      //   route: "/home"
      // });
      // entries.push({
      //   text: formatMessage(intl,"payroll","menu.viewapprovepayrolls"),
      //   icon: <Visibility />,
      //   route: "/home"
      // });
      // entries.push({
      //   text: formatMessage(intl,"payroll","menu.importpaymentdetails"),
      //   icon: <ImportExport/>,
      //   route: "/home"
      // });
      // entries.push({
      //   text: formatMessage(intl,"payroll","menu.paymentproviderpayments"),
      //   icon: <Payment/>,
      //   route: "/home"
      // });
      // entries.push({
      //   text: formatMessage(intl,"payroll","menu.paymentproviderstatusreport"),
      //   icon: <BarChart/>,
      //   route: "/home"
      // });
      
      //Add Payroll managenent the main menu by contribution
      if (!entries.length) return null;
      return (
        <MainMenuContribution
          {...this.props}
          header={formatMessage(intl,"payroll","mainMenu")}
          icon={<Assignment/>}
          entries={entries}
        />
      );
    }
  }
  
  export default withModulesManager(injectIntl(PayrollMainMenu));