import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Fab, Grid, Paper, Typography, Divider } from "@material-ui/core";
import { SaveAlt, Save } from "@material-ui/icons";
import { TextInput, journalize, PublishedComponent, FormattedMessage, Contributions } from "@openimis/fe-core";
import { saveEntity, createScheme } from "../actions";

const styles = theme => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%"
    },
});

const PROGRAMS_ADD_SCHEME_CONTRIBUTION_KEY = "programs.addscheme";
const PROGRAMS_PROGRAMS_PANELS_CONTRIBUTION_KEY = "programs.addscheme.panels"

class AddSchemePage extends Component {
	
	state = {
		edited: {}
	}
	
    componentDidUpdate(prevPops, prevState, snapshort) {
        if (prevPops.submittingMutation && !this.props.submittingMutation) {
            this.props.journalize(this.props.mutation);
        }
    }

    save = e => {
        this.props.createScheme(this.state.edited, `Create Scheme ${this.state.edited.name} (${this.state.edited.code})`)
        
    }
	
	updateAttribute = (k, v) => {
        this.setState((state) => ({
            edited: { ...state.edited, [k]: v }
        }),
			// e => console.log ("STATE " + JSON.stringify(this.state))
		)
    }
	
	render() {
        const {
            intl, classes, edited,
            title = "Scheme.title", titleParams = { label: "" },
            readOnly = true, actions
        } = this.props;
        return (
            <Grid container>
                <Grid item xs={12}>
                <Contributions {...this.props} updateAttribute={this.updateAttribute} contributionKey={PROGRAMS_ADD_SCHEME_CONTRIBUTION_KEY} />
                    <Paper className={classes.paper}>
                        <Grid container className={classes.tableTitle}>
                            <Grid item xs={6} className={classes.tableTitle}>
                                <Typography>
                                    <FormattedMessage module="scheme" id={title} values={titleParams} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container className={classes.item}>
                        <Grid item xs={6} className={classes.item}>
                                <TextInput
							        module="scheme" 
                                    label="SchemeForm.code"
							        value={edited.code}
							        required={true}
                                    onChange={v => this.updateAttribute("code", v)}
                                    inputProps={{
                                        "maxLength": this.codeMaxLength,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.item}>
                                <TextInput
							        module="scheme" 
                                    label="SchemeForm.name"
							        value={edited.name}
							        required={true}
                                    onChange={v => this.updateAttribute("name", v)}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.item}>
                                <PublishedComponent
                                    pubRef="scheme.SchemeTypePicker"
                                    value={edited.schemeType}
                                    required={true}
                                    onChange={v => this.updateAttribute("schemeType", v)}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.item}>
                                <TextInput
							        module="scheme" 
                                    label="SchemeForm.amount"
							        value={edited.amount}
							        required={true}
                                    onChange={v => this.updateAttribute("amount", v)}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.item}>
                                <PublishedComponent
                                    pubRef="scheme.SchemeStatusPicker"
                                    value={edited.status}
                                    required={true}
                                    onChange={v => this.updateAttribute("status", v)}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.item}>
                                <PublishedComponent 
							        pubRef="core.DatePicker"
							        label="schemeForm.startDate"
							        value={edited.startDate}
							        required={true}
                                    onChange={v => this.updateAttribute("startDate", v)}
						        />
                            </Grid>
                            
                        </Grid>
                        <div className={classes.fab} variant="contained" style={{display: 'flex', justifyContent: 'right'}}>
					        <Fab color="primary" onClick={this.save}>
						        <Save />
					        </Fab>
                        </div>
                    </Paper>
                    <Contributions {...this.props} updateAttribute={this.updateAttribute} contributionKey={PROGRAMS_PROGRAMS_PANELS_CONTRIBUTION_KEY} />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state, props) => ({
    submittingMutation: state.programs.submittingMutation,
    mutation: state.programs.mutation,
});

const mapDispatchToProps = dispatch => {
	return bindActionCreators({createScheme, journalize}, dispatch)
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddSchemePage)));