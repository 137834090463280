import React, { Component } from "react";
import { connect } from "react-redux";
import { Edit as EditIcon } from "@material-ui/icons";
import { historyPush, withModulesManager, withHistory } from "@openimis/fe-core";
import PayrollPage from "../pages/PayrollPage";


class PayrollOverviewPage extends Component {
    render() {
        const { history, modulesManager, payroll_uuid } = this.props;
        var actions = [{
            doIt: e => historyPush(modulesManager, history, "payroll.route.payroll", [payroll_uuid]),
            icon: <EditIcon />,
            onlyIfDirty: false
        }]
        return <PayrollPage {...this.props} readOnly={true} overview={true} actions={actions} />
    }
}

const mapStateToProps = (state, props) => ({
    payroll_uuid: props.match.params.payroll_uuid,
})

export default withHistory(withModulesManager(connect(mapStateToProps)(PayrollOverviewPage)));