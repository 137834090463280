import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from 'react-intl';
import { fetchPaypointStr } from "../actions";
import { formatMessage, AutoSuggestion, withModulesManager } from "@openimis/fe-core";
import { SchemeLabel } from "../utils";
import _ from "lodash";
import {Grid } from "@material-ui/core";
import { PublishedComponent } from "@openimis/fe-core";

const styles = theme => ({
    page: theme.page,
    item: theme.paper.item,
});

class PaypointPicker extends Component {

    state = {
        paypoint: [],
        edited:{}
    }

    constructor(props) {
        super(props);
        this.selectThreshold = props.modulesManager.getConf("fe-payroll", "Paypoint.selectThreshold", 10);
    }

    componentDidMount() {
        this.setState({ paypoint: this.props.paypoint });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.paypoint, this.props.paypoint)) {
            this.setState({ paypoint: this.props.paypoint })
        }
    }

    getServiceProviderFromState(){
        const { edited } = this.state;
        return edited.serviceProvider
    }

    getSuggestions = str => !!str &&
        str.length >= this.props.modulesManager.getConf("fe-payroll", "schemesMinCharLookup", 2) &&
        this.props.fetchPaypointStr(this.props.modulesManager, this.getServiceProviderFromState(), str);

    debouncedGetSuggestion = _.debounce(
        this.getSuggestions,
        this.props.modulesManager.getConf("fe-payroll", "debounceTime", 800)
    )

    onSuggestionSelected = v => this.props.onChange(v, SchemeLabel(v));

    onClear = () => {
        this.setState(
            { paypoint: [] },
            e => this.onSuggestionSelected(null)
        );
    }

    updateAttribute = (k,v)=>{
        this.setState((state) => (
            {
                edited: {...state.edited, [k]:v}
            }),
            e => console.log("STATE" + JSON.stringify(this.state))
        )
    }

    render() {
        const { intl, classes, value, reset,  withLabel = true, label, overview=false,
            readOnly = false, required = false, withNull = true, nullLabel = null, serviceProvider=null} = this.props;
        const { paypoint, edited } = this.state;

        return(
            <Grid container>
                <Grid item xs={6} className={classes.item}>
                    <PublishedComponent
                        pubRef="payroll.ServiceProviderPicker"
                        module="payroll"
                        readOnly={readOnly}
                        value={edited.serviceProvider}
                        onChange={v => this.updateAttribute('schemeType', { id: v })}
                    />
                </Grid>
                <Grid item xs={6} className={classes.item}>
                    <AutoSuggestion
                        module="payroll"
                        items={paypoint}
                        label={!!withLabel && (label || formatMessage(intl, "payroll", "Paypoint.label"))}
                        lookup={SchemeLabel}
                        onClear={this.onClear}
                        getSuggestions={this.debouncedGetSuggestion}
                        renderSuggestion={a => <span>{SchemeLabel(a)}</span>}
                        getSuggestionValue={SchemeLabel}
                        onSuggestionSelected={this.onSuggestionSelected}
                        value={value}
                        reset={reset}
                        readOnly={readOnly}
                        required={required}
                        selectThreshold={this.selectThreshold}
                        withNull={withNull}
                        nullLabel={nullLabel || formatMessage(intl, "payroll", "payroll.Paypoint.null")}
                    />
            </Grid>
        </Grid>
        )
    }
}

const mapStateToProps = state => ({
    rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    paypoint: state.payroll.paypoint,
    edited: state.edited,
});

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ fetchPaypointStr }, dispatch);
};

export default withModulesManager(connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(PaypointPicker)))));