import React, { Component, Fragment } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Divider, Checkbox, FormControlLabel } from "@material-ui/core";
import {
    formatMessage, withTooltip,
    FormattedMessage, PublishedComponent, FormPanel,
    TextInput, Contributions, withModulesManager
} from "@openimis/fe-core";

const styles = theme => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%"
    },
});

const PAYROLL_PAYROLL_CONTRIBUTION_KEY = "payroll.Payroll"
const PAYROLL_PAYROLL_PANELS_CONTRIBUTION_KEY = "payroll.Payroll.panels"

class PayrollMasterPanel extends FormPanel {

    render() {
        const {
            intl, classes, payroll,
            title = "payroll.title", titleParams = { label: "" },
            title_insuree = "payroll.Insuree.title",
            readOnly = true,
            actions
        } = this.props;
        return (
            <Grid container>
                <Grid item xs={12}>
                <Paper className={classes.paper}>
                        <Grid container className={classes.item}>
                        < Grid container className={classes.tableTitle}>
                            <Grid item xs={3} className={classes.tableTitle}>
                                    <Typography>
                                        <FormattedMessage module="payroll" id={title} values={titleParams} />
                                    </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid item xs={3} className={classes.item}>
                            <TextInput
                                module="payroll"
                                label="payroll.expectedamount"
                                value={payroll.expectedamount}
                                onChange={v => this.updateAttribute('expectedamount', v)}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <TextInput
                                module="payroll"
                                label="payroll.startDate"
                                value={payroll.startDate}
                                onChange={v => this.updateAttribute('startDate', v)}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <TextInput
                                module="payroll"
                                label="payroll.endDate"
                                value={payroll.endDate}
                                onChange={v => this.updateAttribute('endDate', v)}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <TextInput
                                module="payroll"
                                label="payroll.transaction_number"
                                value={payroll.transactionNumber}
                                onChange={v => this.updateAttribute('transactionNumber', v)}
                            />
                        </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                    <   Grid container className={classes.item}>
                            <Grid container className={classes.tableTitle}>
                                <Grid item xs={3} className={classes.tableTitle}>
                                    <Typography>
                                        <FormattedMessage module="payroll" id={title_insuree} values={titleParams} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container justify="flex-end">
                                        {!!actions && (actions.map((a, idx) => {
                                            return (
                                                <Grid item key={`form-action-${idx}`} className={classes.paperHeaderAction}>
                                                    {withTooltip(a.button, a.tooltip)}
                                                </Grid>
                                            )
                                        }))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container className={classes.item}>
                            <Grid item xs={4} className={classes.item}>
                                <TextInput
                                    module="payroll"
                                    label="payroll.insuree.chfId"
                                    required={true}
                                    readOnly={readOnly}
                                    value={!!payroll && !!payroll.insuree && !!payroll.insuree.chfId ? payroll.insuree.chfId : ""}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.item}>
                                <TextInput
                                    module="payroll"
                                    label="payroll.insuree.lastName"
                                    required={true}
                                    readOnly={readOnly}
                                    value={!!payroll && payroll.insuree && !!payroll.insuree.lastName ? payroll.insuree.lastName : ""}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.item}>
                                <TextInput
                                    module="payroll"
                                    label="payroll.insuree.otherNames"
                                    required={true}
                                    readOnly={readOnly}
                                    value={!!payroll && !!payroll.insuree && !!payroll.insuree.otherNames ? payroll.insuree.otherNames : ""}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container>
                                    <Grid item xs={3} className={classes.item}>
                                        <PublishedComponent pubRef="core.DatePicker"
                                            value={!!payroll ? payroll.dob : null}
                                            module="insuree"
                                            label="Insuree.dob"
                                            readOnly={readOnly}
                                            required={true}
                                            onChange={v => this.updateAttribute('dob', v)}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className={classes.item}>
                                        <PublishedComponent pubRef="insuree.InsureeGenderPicker"
                                            value={!!payroll && !!payroll.insuree && !!payroll.insuree.gender ? payroll.insuree.gender.code : ""}
                                            module="insuree"
                                            readOnly={readOnly}
                                            withNull={true}
                                            nullLabel={formatMessage(intl, "insuree", "InsureeGender.none")}
                                            onChange={v => this.updateAttribute('gender', { code: v })}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className={classes.item}>
                                        <PublishedComponent pubRef="insuree.InsureeMaritalStatusPicker"
                                            value={!!payroll && !!payroll.insuree && !!payroll.insuree.marital ? payroll.insuree.marital : ""}
                                            module="insuree"
                                            readOnly={readOnly}
                                            withNull={true}
                                            nullLabel="InsureeMaritalStatus.N"
                                            onChange={v => this.updateAttribute('marital', v)}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12}>
                                        <PublishedComponent pubRef="insuree.InsureeAddress"
                                            value={payroll.insuree}
                                            module="insuree"
                                            readOnly={readOnly}
                                            onChangeLocation={v => this.updateAttribute('currentVillage', v)}
                                            onChangeAddress={v => this.updateAttribute('currentAddress', v)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} className={classes.item}>
                                        <TextInput
                                            module="insuree"
                                            label="insuree.Insuree.phone"
                                            readOnly={readOnly}
                                            value={!!payroll && !!payroll.insuree.phone ? payroll.insuree.phone : ""}
                                            onChange={v => this.updateAttribute('phone', v)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} className={classes.item}>
                                        <TextInput
                                            module="insuree"
                                            label="insuree.Insuree.email"
                                            readOnly={readOnly}
                                            value={!!payroll && !!payroll.insuree.email ? payroll.insuree.email : ""}
                                            onChange={v => this.updateAttribute('email', v)}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className={classes.item}>
                                        <PublishedComponent pubRef="insuree.ProfessionPicker"
                                            module="insuree"
                                            value={!!payroll && !!payroll.insuree.profession ? payroll.insuree.profession.id : null}
                                            readOnly={readOnly}
                                            withNull={true}
                                            nullLabel={formatMessage(intl, "insuree", "Profession.none")}
                                            onChange={v => this.updateAttribute('profession', { id: v })}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className={classes.item}>
                                        <PublishedComponent pubRef="insuree.EducationPicker"
                                            module="insuree"
                                            value={!!payroll && !!payroll.insuree.education ? payroll.insuree.education.id : ""}
                                            readOnly={readOnly}
                                            withNull={true}
                                            nullLabel={formatMessage(intl, "insuree", "insuree.Education.none")}
                                            onChange={v => this.updateAttribute('education', { id: v })}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className={classes.item}>
                                        <PublishedComponent pubRef="insuree.IdentificationTypePicker"
                                            module="insuree"
                                            value={!!payroll && !!payroll.insuree.typeOfId ? payroll.insuree.typeOfId.code : null}
                                            readOnly={readOnly}
                                            withNull={true}
                                            nullLabel={formatMessage(intl, "insuree", "IdentificationType.none")}
                                            onChange={v => this.updateAttribute('typeOfId', { code: v })}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className={classes.item}>
                                        <TextInput
                                            module="insuree"
                                            label="Insuree.passport"
                                            readOnly={readOnly}
                                            value={!!payroll && !!payroll.insuree.passport ? payroll.insuree.passport : ""}
                                            onChange={v => this.updateAttribute('passport', !!v ? v : null)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} className={classes.item}>
                                <PublishedComponent pubRef="insuree.Avatar"
                                    photo={!!payroll ? payroll.insuree.photo : null}
                                    readOnly={readOnly}
                                    withMeta={true}
                                    onChange={v => this.updateAttribute('photo', !!v ? v : null)}
                                />
                            </Grid>
                            <Contributions {...this.props} updateAttribute={this.updateAttribute} contributionKey={PAYROLL_PAYROLL_CONTRIBUTION_KEY} />
                        </Grid>
                        </Grid>
                    </Paper>
                    <Contributions {...this.props} updateAttribute={this.updateAttribute} contributionKey={PAYROLL_PAYROLL_PANELS_CONTRIBUTION_KEY} />
                </Grid>
            </Grid>
        );
    }
}

export default withModulesManager(withTheme(
    withStyles(styles)(PayrollMasterPanel)
));