export const SCHEME_TYPES = ["cash", "in-kind", "cash-in-kind"] 
export const SCHEME_STATUS = ["active", "inactive"]
export const FREQUENCY_TYPE = ["weekly", "monthly", "annually"]
export const INSUREE_ACTIVE = ["active", "in-active"] 

export const RIGHT_SCHEME = 134001; //101000 in doc ... but in practice...
export const RIGHT_SCHEME_SEARCH = 134001;
export const RIGHT_SCHEME_ADD = 134002;
export const RIGHT_SCHEME_EDIT = 134003;
export const RIGHT_SCHEME_DELETE = 134004;

export const RIGHT_PROGRAMS = 134005; //101000 in doc ... but in practice...
export const RIGHT_PROGRAMS_SEARCH = 134005;
export const RIGHT_PROGRAMS_ADD = 134006;
export const RIGHT_PROGRAMS_EDIT = 134007;
export const RIGHT_PROGRAMS_DELETE = 134008;