import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { AssignmentInd } from "@material-ui/icons";
import { formatMessage, MainMenuContribution, withModulesManager } from "@openimis/fe-core";
import {
  RIGHT_SCHEME,
  RIGHT_PROGRAMS
} from "../constants";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AddIcon from '@material-ui/icons/Add';

const PROGRAMS_MAIN_MENU_CONTRIBUTION_KEY = "programs.MainMenu";

class ProgramsMainMenu extends Component {
  render() {
    const { modulesManager, rights } = this.props;
    let entries = [];
    // if (rights.includes(RIGHT_SCHEME)) {
    //   entries.push(
    //     {
    //       text: formatMessage(this.props.intl, "programs", "menu.schemes"),
    //       icon: <AccountBalanceIcon />,
    //       route: "/" + modulesManager.getRef("programs.route.schemes")
    //     }
    //   )
    // }

    // if (rights.includes(RIGHT_PROGRAMS)) {
    //   entries.push(
    //     {
    //       text: formatMessage(this.props.intl, "programs", "menu.insureetoscheme"),
    //       icon: <AddIcon />,
    //       route: "/" + modulesManager.getRef("programs.route.insureetoscheme")
    //     }
    //   )
    // }

    // if (rights.includes(RIGHT_PROGRAMS)) {
    //   entries.push(
    //     {
    //       text: formatMessage(this.props.intl, "programs", "menu.programs"),
    //       icon: <AddIcon />,
    //       route: "/" + modulesManager.getRef("programs.route.programs")
    //     }
    //   )
    // }

    if (!entries.length) return null;
    return (
      <MainMenuContribution
        {...this.props}
        header={formatMessage(this.props.intl, "programs", "mainMenu")}
        icon={<AssignmentInd />}
        entries={entries}
      />
    );
  }
}

const mapStateToProps = state => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
});

export default withModulesManager(injectIntl(connect(mapStateToProps)(ProgramsMainMenu)));
