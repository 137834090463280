import React, { Component } from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { SCHEME_TYPES } from "../constants";

class SchemeTypePicker extends Component {

    render() {
        const { readOnly = false, required = false, withNull = true, nullLabel = null } = this.props;
        
        return <ConstantBasedPicker
            module="scheme"
            label="Scheme Type"
            constants={SCHEME_TYPES}
            readOnly={readOnly}
            {...this.props}
        />
    }
}

export default SchemeTypePicker;