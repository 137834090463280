import React, { Component } from "react";
import { connect } from "react-redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { formatMessageWithValues, historyPush, formatMessage, withTooltip } from "@openimis/fe-core";
import { fetchMyEntities } from "../actions";
import { bindActionCreators } from "redux";
import { injectIntl } from 'react-intl';
import { ProgressOrError, Table } from "@openimis/fe-core";
import {Button, Fab, Grid, Paper, Divider, Typography, } from "@material-ui/core";
import { Add } from "@material-ui/icons";




// const styles = theme => ({ 
//     page: theme.page,
// });

const styles = theme => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%"
    },
});

class Bankpage extends Component {
    

    state = {
        page: 0,
        pageSize: 10,
        afterCursor: null,
        beforeCursor: null,
    }

    constructor(props) {
        super(props);
        this.rowsPerPageOptions = props.modulesManager.getConf("fe-banks", "rowsPerPageOptions", [5, 10, 15, 50, 100]);
    }

    componentDidMount() {
        this.query();
    }

    query = () => {
        let prms = [];
        prms.push(`first: ${this.state.pageSize}`);
        if (!!this.state.afterCursor) {
            prms.push(`after: "${this.state.afterCursor}"`)
        }
        if (!!this.state.beforeCursor) {
            prms.push(`before: "${this.state.beforeCursor}"`)
        }
        prms.push(`orderBy: ["bankName"]`);
        this.props.fetchMyEntities(prms);
    }

    onChangeRowsPerPage = (cnt) => {
        this.setState(
            {
                pageSize: cnt,
                page: 0,
                afterCursor: null,
                beforeCursor: null,
            },
            e =>this.query()
        )
    }

    onChangePage = (page, nbr) => {
        if (nbr > this.state.page) {
            this.setState((state, props) => ({
                page: state.page + 1,
                beforeCursor: null,
                afterCursor: props.bankPageInfo.endCursor,
            }),
                e => this.query()
            )
        } else if (nbr < this.state.page) {
            this.setState((state, props) => ({
                page: state.page - 1,
                beforeCursor: props.bankPageInfo.startCursor,
                afterCursor: null,
            }),
                e => this.query()
            )
        }
    }

    onAdd = () => {
        historyPush(this.props.modulesManager, this.props.history, "bank.route.add_bank");
    }

    render() {
        const { intl, classes, fetchingBanks, errorBanks, Banks, bankPageInfo } = this.props;

        let headers = [ 
            "Banks.bankName",
            "Banks.bankEmail",
            "Banks.bankAddress",
            "Banks.bankSwitchCode",
            "Banks.bankContactDetail",
        ]

        let itemFormatters = [
            e => e.bankName,
            e => e.bankEmail,
            e => e.bankAddress,
            e => e.bankSwitchCode,
            e => e.bankContactDetail,
        ]

        return(
            <div className={classes.page}>
                <ProgressOrError progress={fetchingBanks} error={errorBanks} />
                    <Paper className={classes.paper}>      
                        <Table
                            module="banks"
                            header={formatMessageWithValues(intl, "Banks", "Banks.Table", {count: bankPageInfo.totalCount})}
                            headers={headers}
                            itemFormatters={itemFormatters}
                            items={Banks}
                            withPagination={true}
                            page={this.state.page}
                            pageSize={this.state.pageSize}
                            count={bankPageInfo.totalCount}
                            onChangePage={this.onChangePage}
                            onChangeRowsPerPage={this.onChangeRowsPerPage}
                            rowsPerPageOptions={this.rowsPerPageOptions}
                        />
                        
                    </Paper> 
                    {withTooltip(
                            <div align="right" className={classes.fab}>
                                <Fab color="primary"
                                    onClick={this.onAdd}>
                                        <Add />
                                    </Fab>
                            </div>,
                            formatMessage(intl, "bank", "addNewBankTooltip")
                        )
                        }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    fetchingBanks: state.bank.fetchingBanks,
    errorBanks: state.bank.errorBanks,
    fetchedBanks: state.bank.fetchedBanks,
    Banks: state.bank.Banks,
    bankPageInfo: state.bank.bankPageInfo,
});

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ fetchMyEntities }, dispatch)
}
export default injectIntl(withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Bankpage))));