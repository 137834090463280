import React, { Component } from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { PAYMENT_TYPES } from "../constants";

class TypeOfPaymentPicker extends Component {

    render() {
        const { readOnly = false, required = false, withNull = true, nullLabel = null } = this.props;
        
        return <ConstantBasedPicker
            module="payroll"
            label="typeOfPaymentPicker"
            constants={PAYMENT_TYPES}
            readOnly={readOnly}
            {...this.props}
        />
    }
}

export default TypeOfPaymentPicker;