import React, { Component } from "react";
import _debounce from "lodash/debounce";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from 'react-intl';
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import {
    withModulesManager, formatMessage,
    Contributions, PublishedComponent, ControlledField, TextInput
} from "@openimis/fe-core";

const styles = theme => ({
    dialogTitle: theme.dialog.title,
    dialogContent: theme.dialog.content,
    form: {
        padding: 0
    },
    item: {
        padding: theme.spacing(1)
    },
    paperDivider: theme.paper.divider,
});

const SCHEME_FILTER_CONTRIBUTION_KEY = "scheme.Filter";

class SchemeFilter extends Component {

    state = {
        showHistory: false,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.filters['showHistory'] !== this.props.filters['showHistory'] &&
            !!this.props.filters['showHistory'] &&
            this.state.showHistory !== this.props.filters['showHistory']['value']
        ) {
            this.setState((state, props) => ({ showHistory: props.filters['showHistory']['value'] }))
        }
    }

    debouncedOnChangeFilter = _debounce(
        this.props.onChangeFilters,
        this.props.modulesManager.getConf("fe-scheme", "debounceTime", 800)
    )

    _filterValue = k => {
        const { filters } = this.props;
        return !!filters && !!filters[k] ? filters[k].value : null
    }

    _onChangeShowHistory = () => {
        let filters = [
            {
                id: 'showHistory',
                value: !this.state.showHistory,
                filter: `showHistory: ${!this.state.showHistory}`
            }
        ];
        this.props.onChangeFilters(filters);
        this.setState((state) => ({
            showHistory: !state.showHistory
        }));
    }

    render() {
        const { intl, classes, filters, onChangeFilters } = this.props;
        return (
            <Grid container className={classes.form}>
                <ControlledField module="scheme" id="SchemeFilter.name" field={
                    <Grid item xs={6} className={classes.item}>
                        <TextInput
							module="scheme" label="SchemeFilter.name"
                            name="name"
                            value={this._filterValue('name')}
                            onChange={v => this.debouncedOnChangeFilter([
                                {
                                    id: 'name',
                                    value: v,
                                    filter: `name_Icontains: "${v}"`
                                }
                            ])}
						/>
                    </Grid>
                } />
                <ControlledField module="scheme" id="SchemeFilter.code" field={
                    <Grid item xs={6} className={classes.item}>
                        <TextInput
                            module="scheme" label="Scheme.code"
                            name="code"
                            value={this._filterValue('code')}
                            onChange={v => this.debouncedOnChangeFilter([
                                {
                                    id: 'code',
                                    value: v,
                                    filter: `code_Icontains: "${v}"`
                                }
                            ])}
                        />
                    </Grid>
                } />
                <ControlledField module="scheme" id="SchemeFilter.schemeType" field={
                    <Grid item xs={6}>
                        <PublishedComponent pubRef="scheme.SchemeTypePicker"
                            value={this._filterValue('schemeType')}
                            module="scheme"
                            label="Scheme.schemeType"
                            onChange={d => onChangeFilters([
                                {
                                    id: 'schemeType',
                                    value: d,
                                    filter: `schemeType: "${d}"`
                                }
                            ])}
                        />
                    </Grid>
                } />
                <ControlledField module="scheme" id="SchemeFilter.amount" field={
                    <Grid item xs={6} className={classes.item}>
                        <TextInput
                            module="scheme" label="Scheme.amount"
                            name="amount"
                            value={this._filterValue('amount')}
                            onChange={v => this.debouncedOnChangeFilter([
                                {
                                    id: 'amount',
                                    value: v,
                                    filter: `amount_Icontains: "${v}"`
                                }
                            ])}
                        />
                    </Grid>
                } />
                <ControlledField module="scheme" id="SchemeFilter.status" field={
                    <Grid item xs={6} className={classes.item}>
                        <PublishedComponent pubRef="scheme.SchemeStatusPicker"
                            value={this._filterValue('schemeStatus')}
                            module="scheme"
                            label="Scheme.schemeStatus"
                            onChange={d => onChangeFilters([
                                {
                                    id: 'schemeStatus',
                                    value: d,
                                    filter: `schemeStatus: "${d}"`
                                }
                            ])}
                        />
                    </Grid>
                } />
                <ControlledField module="scheme" id="SchemeFilter.startDate" field={
                   <Grid item xs={6} className={classes.item}>
                        <PublishedComponent pubRef="core.DatePicker"
                            value={this._filterValue('startDate')}
                            module="scheme"
                            label="Scheme.startDate"
                            onChange={d => onChangeFilters([
                                {
                                    id: 'startDate',
                                    value: d,
                                    filter: `startDate_Gte: "${d}"`
                                }
                            ])}
                        />
                    </Grid>
                } />
                <ControlledField module="scheme" id="SchemeFilter.endDate" field={
                    <Grid item xs={6} className={classes.item}>
                        <PublishedComponent pubRef="core.DatePicker"
                            value={this._filterValue('endDate')}
                            module="scheme"
                            label="Scheme.endDate"
                            onChange={d => onChangeFilters([
                                {
                                    id: 'endDate',
                                    value: d,
                                    filter: `endDate_Lte: "${d}"`
                                }
                            ])}
                        />
                    </Grid>
                } />
                <Grid item xs={6}>
                    <Grid container>
                        <ControlledField module="scheme" id="SchemeFilter.showHistory" field={
                            <Grid item xs={6} className={classes.item}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={this.state.showHistory}
                                            onChange={e => this._onChangeShowHistory()}
                                        />
                                    }
                                    label={formatMessage(intl, "scheme", "SchemeFilter.showHistory")}
                                />
                            </Grid>
                        } />
                    </Grid>
                </Grid>
                <Contributions filters={filters} onChangeFilters={onChangeFilters} contributionKey={SCHEME_FILTER_CONTRIBUTION_KEY} />
            </Grid>
        )
    }
}

export default withModulesManager(injectIntl((withTheme(withStyles(styles)(SchemeFilter)))));