import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import ReplayIcon from "@material-ui/icons/Replay";
import {
    formatMessageWithValues, withModulesManager, withHistory, historyPush, journalize,
    Form, ProgressOrError
} from "@openimis/fe-core";
import { RIGHT_SCHEME } from "../constants";
// import ProgramsDisplayPanel from "./ProgramsDisplayPanel";
// import ProgramsMasterPanel from "./ProgramsMasterPanel";
import AddProgramsPage from "../pages/AddProgramsPage";


import { fetchSchemeFull, fetchPrograms } from "../actions";
import { SchemeLabel } from "../utils";

const styles = theme => ({
    page: theme.page,
});

const PROGRAMS_SCHEME_FORM_CONTRIBUTION_KEY = "programs.SchemeForm"

class ProgramsForm extends Component {

    state = {
        lockNew: false,
        reset: 0,
        scheme: this._newScheme(),
        newScheme: true,
    }

    _newScheme() {
        let scheme = {};
        scheme.jsonExt = {};
        return scheme;
    }

    componentDidMount() {
        document.title = formatMessageWithValues(this.props.intl, "programs", "Scheme.title", { label: "" })
        if (!!this.props.scheme_uuid) {
            this.setState(
                (state, props) => ({ scheme_uuid: props.scheme_uuid }),
                e => this.props.fetchSchemeFull(
                    this.props.modulesManager,
                    this.props.scheme_uuid
                )
            )
        } else if (!!this.props.programs_uuid && (!this.props.programs || this.props.programs.uuid !== this.props.programs_uuid)) {
            this.props.fetchPrograms(this.props.modulesManager, this.props.programs_uuid)
        } else if (!!this.props.programs_uuid) {
            let scheme = { ...this.state.scheme }
            scheme.programs = { ...this.props.programs }
            this.setState({ programs })
        }
    }

    back = e => {
        const { modulesManager, history, programs_uuid, scheme_uuid } = this.props;
        if (programs_uuid) {
            historyPush(modulesManager,
                history,
                "programs.route.programsOverview",
                [programs_uuid]
            );
        } else {
            historyPush(modulesManager,
                history,
                "programs.route.schemes"
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevState.scheme && prevState.scheme.code)
            !== (this.state.scheme && this.state.scheme.code)) {
            document.title = formatMessageWithValues(this.props.intl, "programs", "Scheme.title", { label: SchemeLabel(this.state.scheme) })
        }
        if (prevProps.fetchedScheme !== this.props.fetchedScheme && !!this.props.fetchedScheme) {
            var scheme = this.props.scheme || {};
            scheme.ext = !!scheme.jsonExt ? JSON.parse(scheme.jsonExt) : {};
            this.setState(
                { scheme, scheme_uuid: scheme.uuid, lockNew: false, newScheme: false });
        } else if (prevProps.scheme_uuid && !this.props.scheme_uuid) {
            document.title = formatMessageWithValues(this.props.intl, "programs", "Scheme.title", { label: SchemeLabel(this.state.scheme) })
            this.setState({ scheme: this._newScheme(), newScheme: true, lockNew: false, scheme_uuid: null });
        } else if (prevProps.submittingMutation && !this.props.submittingMutation) {
            this.props.journalize(this.props.mutation);
            this.setState({ reset: this.state.reset + 1 });
        }
    }

    _add = () => {
        this.setState((state) => ({
            scheme: this._newScheme(),
            newScheme: true,
            lockNew: false,
            reset: state.reset + 1,
        }),
            e => {
                this.props.add();
                this.forceUpdate();
            }
        )
    }

    reload = () => {
        this.props.fetchSchemeFull(
            this.props.modulesManager,
            this.state.scheme_uuid
        );
    }

    canSave = () => {
        if (!this.state.scheme.code) return false;
        if (!this.state.scheme.name) return false;
        if (!this.state.scheme.schemeType) return false;
        // if (!this.state.scheme.amount) return false;
        if (!this.state.scheme.status) return false;
        return true;
    }

    _save = (scheme) => {
        this.setState(
            { lockNew: !scheme.uuid }, // avoid duplicates
            e => this.props.save(scheme))
    }

    onEditedChanged = scheme => {
        this.setState({ scheme, newScheme: false })
    }

    render() {
        const { rights,
            scheme_uuid, fetchingScheme, fetchedScheme, errorScheme,
            programs, programs_uuid, fetchingPrograms, fetchedPrograms, errorPrograms,
            readOnly = false,
            add, save,
        } = this.props;
        const { scheme } = this.state;
        if (!rights.includes(RIGHT_SCHEME)) return null;
        let actions = [{
            doIt: this.reload,
            icon: <ReplayIcon />,
            onlyIfDirty: !readOnly
        }];
        return (
            <Fragment>
                <ProgressOrError progress={fetchingScheme} error={errorScheme} />
                <ProgressOrError progress={fetchingPrograms} error={errorPrograms} />
                {((!!fetchedScheme && !!scheme && scheme.uuid === scheme_uuid) || !scheme_uuid) &&
                    ((!!fetchedPrograms && !!programs && programs.uuid === programs_uuid) || !programs_uuid) &&
                    (
                        <Form
                            module="programs"
                            title="Scheme.title"
                            titleParams={{ label: SchemeLabel(this.state.scheme) }}
                            edited_id={scheme_uuid}
                            edited={this.state.scheme}
                            reset={this.state.reset}
                            back={this.back}
                            add={!!add && !this.state.newScheme ? this._add : null}
                            readOnly={readOnly || !!scheme.validityTo}
                            actions={actions}
                            HeadPanel={AddProgramsPage}
                            // HeadPanel={ProgramsDisplayPanel}
                            // Panels={[ProgramsMasterPanel]}
                            contributedPanelsKey={PROGRAMS_SCHEME_FORM_CONTRIBUTION_KEY}
                            scheme={this.state.scheme}
                            onEditedChanged={this.onEditedChanged}
                            canSave={this.canSave}
                            save={!!save ? this._save : null}
                        />
                    )}
            </Fragment>
        )
    }
}

const mapStateToProps = (state, props) => ({
    rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    fetchingScheme: state.programs.fetchingScheme,
    errorScheme: state.programs.errorScheme,
    fetchedScheme: state.programs.fetchedScheme,
    scheme: state.programs.scheme,
    fetchingPrograms: state.programs.fetchingPrograms,
    errorPrograms: state.programs.errorPrograms,
    fetchedPrograms: state.programs.fetchedPrograms,
    programs: state.programs.programs,
    submittingMutation: state.programs.submittingMutation,
    mutation: state.programs.mutation,
})

export default withHistory(withModulesManager(connect(mapStateToProps, { fetchSchemeFull, fetchPrograms, journalize })(
    injectIntl(withTheme(withStyles(styles)(ProgramsForm))
    ))));