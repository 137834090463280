import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    withModulesManager,
    ProgressOrError
} from "@openimis/fe-core";
import PayrollcycleMasterPanel from "./PayrollcycleMasterPanel";
import { fetchPayrollcycle } from "../../actions";

class PayrollcycleDisplayPanel extends Component {
    state = {
        payrollcycle: null
    }

    componentDidMount() {
        // if (!!this.props.payrollcycle_uuid) {
            this.props.fetchPayrollcycle(this.props.modulesManager, this.props.payrollcycle_uuid)
        // } else {
        //     this.setState({ payrollcycle: this.props.payrollcycle })
        // }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.payrollcycle_uuid !== this.props.payrollcycle_uuid) {
            this.setState(
                { payrollcycle: null },
                e => this.props.fetchPayrollcycle(this.props.modulesManager, this.props.payrollcycle_uuid)
            );
        } else if (!prevProps.fetchedPayrollcycle && !!this.props.fetchedPayrollcycle) {
            this.setState({ payrollcycle: this.props.payrollcycle })
        }
    }

    render() {
        const { fetchingPayrollcycle, errorPayrollcycle } = this.props
        return (
            <Fragment>
                <ProgressOrError progress={fetchingPayrollcycle} error={errorPayrollcycle} />
                {!!this.state.payrollcycle && (
                    <PayrollcycleMasterPanel {...this.props} readOnly={true} edited={this.state.payrollcycle} overview={true}/>
                )}                        

            </Fragment>
            
        );
    }
}

const mapStateToProps = (state, props) => ({
    fetchingPayrollcycle: state.payroll.fetchingPayrollcycle,
    errorPayrollcycle: state.payroll.errorPayrollcycle,
    fetchedPayrollcycle: state.payroll.fetchedPayrollcycle,
    payrollcycle: state.payroll.payrollcycle,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ fetchPayrollcycle }, dispatch);
};

export default withModulesManager(connect(mapStateToProps, mapDispatchToProps)(PayrollcycleDisplayPanel))