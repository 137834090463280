import React, { Component } from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { FREQUENCY_TYPE } from "../constants";

class FrequencyTypePicker extends Component {

    render() {
        const { readOnly = false, required = false, withNull = true, nullLabel = null } = this.props;
        
        return <ConstantBasedPicker
            module="scheme"
            label="Frequency Type"
            constants={FREQUENCY_TYPE}
            readOnly={readOnly}
            {...this.props}
        />
    }
}

export default FrequencyTypePicker;