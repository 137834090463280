import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Fab, Grid, Paper, Typography, Divider, Button } from "@material-ui/core";
import { journalize, TextInput, PublishedComponent, FormattedMessage, Contributions } from "@openimis/fe-core";
import { savePrograms, saveDiaf } from "../actions";
import { SaveAlt, Save } from "@material-ui/icons";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { createPrograms } from "../actions";

const styles = theme => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%"
    },
});

const PROGRAMS_ADD_PROGRAMS_CONTRIBUTION_KEY = "programs.addprograms";


class AddProgramsPage extends Component {

    state = {
        state_edited: {},
        divcontainer: false,
        edited: {
            //Income
            totalIncome: "0.00",
            salariesWages: "0.00",
            pensionPayment: "0.00",
            childAllowance: "0.00",
            localMarketSales: "0.00",
            mfnPrivateProduceShipment: "0.00",
            handicraftSales: "0.00",
            overseasRemittances: "0.00",
            othersIncome: "0.00",


            //Expenditure,
            totalExpenditure: "0.00",
            householdExpenses: "0.00",
            eps: "0.00",
            telecom: "0.00",
            bcn: "0.00",
            petrol: "0.00",
            houseLoan: "0.00",
            othersExpenditure: "0.00",
            loans: "0.00",

            //loans

            westpac: "0.00",
            psis: "0.00",
            ndb: "0.00",
            savingsLoan: "0.00",

            difference: "0.00",
        },
    }

    saveDiaf = e => {
        this.props.saveDiaf(this.state.edited, `Creating ${this.state.edited.insuree},`)

    }


    sumIncome = (edited) => {
        let sumOfIncome = 0;
        sumOfIncome = parseFloat(edited.salariesWages) + parseFloat(edited.pensionPayment) + parseFloat(edited.childAllowance) +
            parseFloat(edited.localMarketSales) + parseFloat(edited.mfnPrivateProduceShipment) + parseFloat(edited.handicraftSales) +
            parseFloat(edited.overseasRemittances) + parseFloat(edited.othersIncome);
        return sumOfIncome;
    }
    sumExpenditure = (edited) => {
        let sumOfExpense = 0;
        sumOfExpense = parseFloat(edited.householdExpenses) + parseFloat(edited.eps) + parseFloat(edited.telecom) +
            parseFloat(edited.bcn) + parseFloat(edited.petrol) + parseFloat(edited.houseLoan) + parseFloat(edited.othersExpenditure) + parseFloat(edited.westpac) + parseFloat(edited.psis) + parseFloat(edited.ndb) + parseFloat(edited.savingsLoan);
        return sumOfExpense;
    }
    sumLoan = (edited) => {
        return parseFloat(edited.westpac) + parseFloat(edited.psis) + parseFloat(edited.ndb) + parseFloat(edited.savingsLoan)
    }

    // calcDifference = (edited) =>{
    //     return parseFloat(edited.totalIncome) - parseFloat(edited.totalExpenditure);
    // }

    calcDifference = (edited) => {
        return (parseFloat(edited.salariesWages) + parseFloat(edited.pensionPayment) + parseFloat(edited.childAllowance) +
            parseFloat(edited.localMarketSales) + parseFloat(edited.mfnPrivateProduceShipment) + parseFloat(edited.handicraftSales) +
            parseFloat(edited.overseasRemittances) + parseFloat(edited.othersIncome)) - (parseFloat(edited.householdExpenses) + parseFloat(edited.eps) + parseFloat(edited.telecom) +
                parseFloat(edited.bcn) + parseFloat(edited.petrol) + parseFloat(edited.houseLoan) + parseFloat(edited.othersExpenditure) +
                parseFloat(edited.westpac) + parseFloat(edited.psis) + parseFloat(edited.ndb) + parseFloat(edited.savingsLoan));
    }

    renderBeneficiaryFields = () => {

        const { classes = useStyles(), title = "Diaf.title", titleone = "Diaf.titile1", subtitleone = "Diaf.titleone", subtitletwo = "Diaf.titletwo", subtitlethree = "Diaf.titlethree", titleParams = { label: "" }, } = this.props;
        const { edited } = this.state;

        if (edited.dependencyRelative == "Yes") {
            return (
                <Grid container spacing={0}>
                    <Grid item xs={1} className={classes.item}>
                        <TextInput
                            module="DiaForm" label="AddDiafForm.nameOfBeneficiary"
                            value={edited.nameOfBeneficiary}
                            // value="0.00"
                            // required={true}
                            onChange={v => this.updateAttribute("nameOfBeneficiary", v)}
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.item}>
                        <TextInput
                            module="DiaForm" label="AddDiafForm.addressOfBeneficiary"
                            value={edited.addressOfBeneficiary}
                            // value="0.00"
                            // required={true}
                            onChange={v => this.updateAttribute("addressOfBeneficiary", v)}
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.item}>
                        <PublishedComponent
                            pubRef="niue.SupportingPersonPicker"
                            label="AddDiafForm.description"
                            value={edited.description}
                            // value="0.00"
                            // required={true}
                            onChange={v => this.updateAttribute("description", v)}
                        />
                    </Grid>
                </Grid>

            );
        }

    }

    renderMoneyFields = () => {
        const { classes = useStyles(), title = "Diaf.title", titleone = "Diaf.titile1", subtitleone = "Diaf.titleone", subtitletwo = "Diaf.titletwo", subtitlethree = "Diaf.titlethree", titleParams = { label: "" }, } = this.props;
        const { edited } = this.state;

        if (edited.receiveMoneyFromAnySource === "Yes") {
            return (

                <Grid container spacing={2}>
                    <Grid item xs={6} className={classes.item} ld="1">

                        <TextInput
                            module="DiaForm" label="AddDiafForm.ifYesSpecify"
                            value={edited.ifYesSpecify}
                            // value="0.00"
                            // required={true}
                            onChange={v => this.updateAttribute("ifYesSpecify", v)}
                        />
                    </Grid>
                </Grid>

            )
        }
    }


    updateAttribute = (k, v) => {

        let currentEditedState = this.state.edited;
        let currentSumIncome = this.sumIncome(this.state.edited).toString();
        let currentSumExpentiture = this.sumExpenditure(this.state.edited).toString();
        let currentLoans = this.sumLoan(this.state.edited).toString();
        let currentDifference = this.calcDifference(this.state.edited).toString();

        this.state.edited.totalIncome = currentSumIncome;
        this.state.edited.totalExpenditure = currentSumExpentiture;
        this.state.edited.loans = currentLoans;
        this.state.edited.difference = currentDifference;

        console.log("Current sum" + currentSumIncome);

        console.log(k);
        console.log(v);


        this.setState((state) => ({
            edited: {
                ...state.edited,
                [k]: v,
            },
        }))
        console.log(this.state.edited);

    }

    renderEmployedFields = () => {
        const { classes = useStyles(), title = "Diaf.title", titleone = "Diaf.titile1", subtitleone = "Diaf.titleone", subtitletwo = "Diaf.titletwo", subtitlethree = "Diaf.titlethree", titleParams = { label: "" }, } = this.props;
        const { edited } = this.state;

        if (edited.employed == "Yes") {

            return (
                <Grid item xs={3} className={classes.item}>
                    <TextInput
                        module="DiaForm" label="AddDiafForm.weekly_wage"
                        value={edited.weeklyWage}
                        // value="0.00"
                        // required={true}
                        onChange={v => this.updateAttribute("weeklyWage", v)}
                    />
                    {/* <select>
                    <option>Yes</option>
                    <option>No</option>
                </select> */}
                </Grid>
            );
        }
    }

    renderNotEmployedFields = () => {

        const { classes = useStyles(), title = "Diaf.title", titleone = "Diaf.titile1", subtitleone = "Diaf.titleone", subtitletwo = "Diaf.titletwo", subtitlethree = "Diaf.titlethree", titleParams = { label: "" }, } = this.props;
        const { edited } = this.state;

        if (edited.employed == "No") {
            return (
                <Grid item xs={3} className={classes.item}>
                    <PublishedComponent
                        pubRef="niue.YesOrNoPicker"
                        label="AddDiafForm.employmentFoundForYou"
                        value={edited.employmentForYou}
                        // value="0.00"
                        // required={true}
                        onChange={v => this.updateAttribute("employmentForYou", v)}
                    />
                </Grid>
            );
        }
    }
    renderEmploymentRejectionFields = () => {

        const { classes = useStyles(), title = "Diaf.title", titleone = "Diaf.titile1", subtitleone = "Diaf.titleone", subtitletwo = "Diaf.titletwo", subtitlethree = "Diaf.titlethree", titleParams = { label: "" }, } = this.props;
        const { edited } = this.state;

        if (edited.employmentForYou == "No") {
            return (
                <Grid item xs={3} className={classes.item}>
                    <TextInput
                        module="DiaForm" label="AddDiafForm.whyNoEmploymentIfFound"
                        value={edited.ifNoWhy}
                        // value="0.00"
                        // required={true}
                        onChange={v => this.updateAttribute("ifNoWhy", v)}
                    />
                </Grid>
            );
        }
    }

    renderCashAssetsFields = () => {

        const { classes = useStyles(), title = "Diaf.title", titleone = "Diaf.titile1", subtitleone = "Diaf.titleone", subtitletwo = "Diaf.titletwo", subtitlethree = "Diaf.titlethree", titleParams = { label: "" }, } = this.props;
        const { edited } = this.state;

        if (edited.cashAssets == "Yes") {
            return (
                <Grid item xs={3} className={classes.item}>
                    <TextInput
                        module="DiaForm" label="AddDiafForm.savingsAccountNumber"
                        value={edited.savingsAccountNumber}
                        // value="0.00"
                        // required={true}
                        onChange={v => this.updateAttribute("savingsAccountNumber", v)}
                    />
                </Grid>
            );
        }


    }

    renderDebtsOwingFields = () => {

        const { classes = useStyles(), title = "Diaf.title", titleone = "Diaf.titile1", subtitleone = "Diaf.titleone", subtitletwo = "Diaf.titletwo", subtitlethree = "Diaf.titlethree", titleParams = { label: "" }, } = this.props;
        const { edited } = this.state;

        if (edited.DebtsOwing == "Yes") {
            return (
                <Grid item xs={3} className={classes.item}>
                    <TextInput
                        module="DiaForm" label="AddDiafForm.ifDebtYesSpecify"
                        value={edited.ifDebtYesSpecify}
                        // value="0.00"
                        // required={true}
                        onChange={v => this.updateAttribute("ifDebtYesSpecify", v)}
                    />
                </Grid>
            );
        }
    }

    componentDidMount() {
        if (this.props.edited) {
            this.setState((state, props) => ({ state_edited: props.edited }))
        }
    }

    componentDidUpdate(prevPops, prevState, snapshort) {
        if (prevPops.submittingMutation && !this.props.submittingMutation) {
            this.props.journalize(this.props.mutation);
        }
    }

    savePr = (e) => {
        console.log("State Edited", this.state.state_edited);
        let insuree = this.state.state_edited.insuree
        let expAmount = this.props.edited.amount
        const programs = {
            insuree,
            expAmount,
            scheme: this.props.edited,

        }
        this.props.savePrograms(programs, `Added Insuree to Scheme`)
    }

    updateAttributePr = (k, v) => {
        this.setState((state) => ({
            state_edited: { ...state.state_edited, [k]: v }
        }),
            // e => console.log ("STATE " + JSON.stringify(this.state))
        )
    }

    render() {
        const {
            intl, classes = useStyles(),
            title = "Programs.title", title1 = "Diaf.title",
            titleone = "Diaf.titile1", subtitleone = "Diaf.titleone",
            subtitletwo = "Diaf.titletwo", subtitlethree = "Diaf.titlethree",
            titleParams = { label: "" },
            readOnly = true, family_uuid,
            actions
        } = this.props;
        const {
            state_edited, edited
        } = this.state
        var HandleChange = e => {
            this.setState({ divcontainer: !this.state.divcontainer })
        }
        const { headers = "Diaf.header", headersParams = { label: "" }, } = this.props;
        const x = this.state.divcontainer;

        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container className={classes.tableTitle}>
                                <Grid item xs={3} className={classes.tableTitle}>
                                    <Typography>
                                        <FormattedMessage module="programs" id={title} values={titleParams} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container className={classes.item}>
                                <Grid item xs={4} className={classes.item}>
                                    <PublishedComponent
                                        pubRef="insuree.InsureePicker"
                                        value={state_edited.insuree}
                                        onChange={(v) => this.updateAttributePr("insuree", v)}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                    <TextInput
                                        module="scheme"
                                        label="schemeForm.amount"
                                        value={edited.amount}
                                        required={false}
                                        onChange={v => this.updateAttributePr("expAmount", v)}
                                    />
                                </Grid>
                            </Grid>
                            <div className={classes.fab} variant="contained" style={{ display: 'flex', justifyContent: 'right' }}>
                                <Fab color="primary" onClick={this.savePr}>
                                    <Save />
                                </Fab>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container className={classes.tableTitle}>
                                <Grid item xs={3} className={classes.tableTitle}>
                                    <Typography>
                                        <Button onClick={HandleChange}>{x ? 'Welfare Benefit Form' : 'Welfare Benefit Form'}
                                        </Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                {
                    x && (<div className={classes.page}>

                        <Grid container>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Grid container className={classes.tableTitle}>
                                        <Grid item xs={6} className={classes.tableTitle}>
                                            <Typography>
                                                <FormattedMessage module="DiaForm" id={titleone} values={titleParams} />

                                            </Typography>

                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container item xs={12} className={classes.item}>

                                        <Grid item xs={12} className={classes.item}>
                                            <PublishedComponent
                                                pubRef="insuree.InsureePicker"
                                                value={edited.insuree}
                                                onChange={(v) => this.updateAttribute("insuree", v)}
                                                required={true}
                                            />
                                        </Grid>

                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.welfareBenefitReason"
                                                value={edited.reasonForApplyingWelfareBenefit}
                                                // value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("reasonForApplyingWelfareBenefit", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <PublishedComponent
                                                pubRef="niue.YesOrNoPicker"
                                                label="AddDiafForm.childrenDependency"
                                                value={edited.dependencyChildren}
                                                required={false}
                                                onChange={v => this.updateAttribute("dependencyChildren", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <PublishedComponent
                                                pubRef="niue.YesOrNoPicker"
                                                label="AddDiafForm.relativeDependency"
                                                value={edited.relativeDependency}
                                                required={false}
                                                onChange={v => this.updateAttribute("relativeDependency", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={3} className={classes.item}>
                                            <PublishedComponent
                                                pubRef="niue.YesOrNoPicker"
                                                label="AddDiafForm.beneficiarySupport"
                                                value={edited.dependencyRelative}
                                                // value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("dependencyRelative", v)}

                                            />
                                        </Grid>
                                        {this.renderBeneficiaryFields()}

                                        <Grid item xs={6} className={classes.item}>
                                            <PublishedComponent
                                                pubRef="niue.YesOrNoPicker"
                                                label="AddDiafForm.receiveSupport"
                                                value={edited.receiveAnySupportAtAll}
                                                // value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("receiveAnySupportAtAll", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item} ld="recmoney">
                                            <PublishedComponent
                                                pubRef="niue.YesOrNoPicker"
                                                label="AddDiafForm.receiveMoney"
                                                value={edited.receiveMoneyFromAnySource}
                                                //     //reference grid with ID boolRecMoney and make it hidden
                                                // }
                                                // value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("receiveMoneyFromAnySource", v)}
                                                display={{ value: "Yes", ld: "1" }}
                                            />
                                        </Grid>

                                        {this.renderMoneyFields()}

                                        <Grid item xs={3} className={classes.item}>
                                            <PublishedComponent
                                                pubRef="niue.YesOrNoPicker"
                                                label="AddDiafForm.employed"
                                                value={edited.employed}
                                                // value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("employed", v)}
                                            />
                                        </Grid>

                                        {this.renderEmployedFields()}
                                        {this.renderNotEmployedFields()}
                                        {this.renderEmploymentRejectionFields()}



                                        <Grid item xs={3} className={classes.item}>
                                            <PublishedComponent
                                                pubRef="niue.YesOrNoPicker"
                                                label="AddDiafForm.cashAssets"
                                                value={edited.cashAssets}
                                                // value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("cashAssets", v)}
                                            />
                                        </Grid>

                                        {this.renderCashAssetsFields()}

                                        <Grid item xs={3} className={classes.item}>
                                            <PublishedComponent
                                                pubRef="niue.YesOrNoPicker"
                                                label="AddDiafForm.debtsOwing"
                                                value={edited.DebtsOwing}
                                                // value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("DebtsOwing", v)}
                                            />
                                        </Grid>

                                        {this.renderDebtsOwingFields()}

                                        <Grid item xs={6} className={classes.item}>
                                            <PublishedComponent
                                                pubRef="niue.YesOrNoPicker"
                                                label="AddDiafForm.interestInProperty"
                                                value={edited.interestInProperty}
                                                // value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("interestInProperty", v)}

                                            />
                                        </Grid>
                                        {/* <Grid item xs={6} className={classes.item}>
                                                    <label className={classes.item}> Testing </label>
                                                        
                                                    <select className={classes.item}>
                                                        {edited.interestInProperty}
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </Grid> */}

                                        <Grid item xs={12} className={classes.tableSubtitle}>
                                            <Typography >
                                                <FormattedMessage module="DiaForm" id={subtitlethree} values={titleParams} />
                                            </Typography>
                                        </Grid>


                                        <Grid item xs={6} className={classes.item}>
                                            <PublishedComponent
                                                pubRef="niue.YesOrNoPicker"
                                                label="niue.fridge"
                                                value={edited.fridge}
                                                required={true}
                                                onChange={v => this.updateAttribute("fridge", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <PublishedComponent
                                                pubRef="niue.YesOrNoPicker"
                                                label="niue.freezer"
                                                value={edited.freezer}
                                                required={true}
                                                onChange={v => this.updateAttribute("freezer", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <PublishedComponent
                                                pubRef="niue.YesOrNoPicker"
                                                label="niue.washingMarchine"
                                                value={edited.washingMarchine}
                                                required={true}
                                                onChange={v => this.updateAttribute("washingMarchine", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <PublishedComponent
                                                pubRef="niue.YesOrNoPicker"
                                                label="niue.fryingPan"
                                                value={edited.fryingPan}
                                                required={true}
                                                onChange={v => this.updateAttribute("fryingPan", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <PublishedComponent
                                                pubRef="niue.YesOrNoPicker"
                                                label="niue.electricOven"
                                                value={edited.electricOven}
                                                required={true}
                                                onChange={v => this.updateAttribute("electricOven", v)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>


                        <Grid container>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Grid container className={classes.tableTitle}>
                                        <Grid item xs={6} className={classes.tableTitle}>
                                            <Typography>
                                                <FormattedMessage module="DiaForm" id={title1} values={titleParams} />

                                            </Typography>

                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container className={classes.item}>

                                        <Grid item xs={6} className={classes.tableSubtitle}>
                                            <Typography>
                                                <FormattedMessage module="DiaForm" id={subtitleone} values={titleParams} />
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6} className={classes.tableSubtitle}>
                                            <Typography >
                                                <FormattedMessage module="DiaForm" id={subtitletwo} values={titleParams} />
                                            </Typography>
                                        </Grid>


                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.salariesWages"
                                                value={edited.salariesWages}
                                                // value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("salariesWages", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.householdExpenses"
                                                value={edited.householdExpenses}

                                                // required={true}
                                                onChange={v => this.updateAttribute("householdExpenses", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.pensionPayment"
                                                value={edited.pensionPayment}
                                                // value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("pensionPayment", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.eps"
                                                value={edited.eps}
                                                // required={true}
                                                onChange={v => this.updateAttribute("eps", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.childAllowance"
                                                value={edited.childAllowance}
                                                // value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("childAllowance", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.telecom"
                                                value={edited.telecom}

                                                // required={true}
                                                onChange={v => this.updateAttribute("telecom", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.localMarketSales"
                                                value={edited.localMarketSales}
                                                // value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("localMarketSales", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.bcn"
                                                value={edited.bcn}

                                                // required={true}
                                                onChange={v => this.updateAttribute("bcn", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.mfnPrivateProduceShipment"
                                                value={edited.mfnPrivateProduceShipment}
                                                // value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("mfnPrivateProduceShipment", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.petrol"
                                                value={edited.petrol}
                                                // required={true}
                                                onChange={v => this.updateAttribute("petrol", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.handicraftSales"
                                                value={edited.handicraftSales}
                                                // required={true}
                                                onChange={v => this.updateAttribute("handicraftSales", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.houseLoan"
                                                value={edited.houseLoan}

                                                // required={true}
                                                onChange={v => this.updateAttribute("houseLoan", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.overseasRemittances"
                                                value={edited.overseasRemittances}
                                                // required={true}
                                                onChange={v => this.updateAttribute("overseasRemittances", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={2} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.loans"
                                                value={this.sumLoan(this.state.edited)}
                                                // required={true}
                                                onChange={(v) => this.updateAttribute("loans", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={1} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.westpac"
                                                value={edited.westpac}
                                                // required={true}
                                                onChange={v => this.updateAttribute("westpac", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={1} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.psis"
                                                value={edited.psis}
                                                // required={true}
                                                onChange={v => this.updateAttribute("psis", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={1} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.ndb"
                                                value={edited.ndb}

                                                // required={true}
                                                onChange={v => this.updateAttribute("ndb", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={1} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.savingsLoan"
                                                value={edited.savingsLoan}
                                                // value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("savingsLoan", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.othersIncome"
                                                value={edited.othersIncome}
                                                // required={true}
                                                onChange={v => this.updateAttribute("othersIncome", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.othersExpenditure"
                                                value={edited.othersExpenditure}

                                                // required={true}
                                                onChange={v => this.updateAttribute("othersExpenditure", v)}
                                            />
                                        </Grid>
                                        {/* TOTALS */}
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.totalIncome"
                                                value={this.sumIncome(this.state.edited)}
                                                // value ={edited.totalIncome}
                                                // required={true}
                                                onChange={v => this.updateAttribute("totalIncome", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.totalExpenditure"
                                                value={this.sumExpenditure(this.state.edited)}
                                                // required={true}
                                                onChange={v => this.updateAttribute("totalExpenditure", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.difference"
                                                // value={edited.difference}
                                                value={this.calcDifference(this.state.edited)}
                                                // required={true}
                                                onChange={v => this.updateAttribute("difference", v)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.item}>
                                            <TextInput
                                                module="DiaForm" label="AddDiafForm.shortfallDisposableIncome"
                                                // value={edited.shortfallDisposableIncome}
                                                value="0.00"
                                                // required={true}
                                                onChange={v => this.updateAttribute("shortfallDisposableIncome", v)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className={classes.fab} variant="contained" style={{ display: 'flex', justifyContent: 'right' }}>
                                        <Fab color="primary" onClick={this.saveDiaf}>
                                            <Save />
                                        </Fab>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                        <br />
                    </div>)
                }
            </div>


        );
    }
}


const mapStateToProps = (state, props) => ({
    submittingMutation: state.programs.submittingMutation,
    mutation: state.programs.mutation,
});

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ savePrograms, saveDiaf, journalize }, dispatch)
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddProgramsPage)));