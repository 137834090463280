import React, { Component, Fragment } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from 'react-intl';
import {
    Paper,
    Grid,
    FormControlLabel,
    Checkbox,
    Typography,
    Divider,
    Tooltip,
    IconButton
} from "@material-ui/core";
import { People as PeopleIcon } from '@material-ui/icons';
import {
    historyPush, withHistory, withModulesManager,
    TextInput, formatMessage, PublishedComponent, FormattedMessage, FormPanel, YearPicker
} from "@openimis/fe-core";

const styles = theme => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%"
    },
});

class PayrollcycleMasterPanel extends FormPanel {
    render() {
        const {
            intl, classes, edited,
            title = "payroll.payrollcycle.title", titleParams = { label: "" },
            readOnly = true,
            actions
        } = this.props;
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container className={classes.item}>
                            <Grid item xs={6} className={classes.item}>
                                <TextInput
                                    module="payroll"
                                    label="payrollcycles.payrollCycleNameInput"
                                    required={true}
                                    value={edited.payrollCycleName}
                                    onChange={v => this.updateAttribute('payrollCycleName', v)}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.item}>
                                <PublishedComponent
                                    pubRef="scheme.DropDownSchemesPicker"
                                    value={edited.scheme}
                                    onChange={(v) => this.updateAttribute("scheme", v)}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.item}>
                                <PublishedComponent
                                    pubRef="core.DatePicker"
                                    label="schemeForm.startDate"
                                    value={edited.startDate}
                                    required={true}
                                    onChange={v => this.updateAttribute("startDate", v)}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.item}>
                                <PublishedComponent
                                    pubRef="core.DatePicker"
                                    label="schemeForm.endDate"
                                    value={edited.endDate}
                                    required={true}
                                    onChange={v => this.updateAttribute("endDate", v)}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withModulesManager(withHistory(injectIntl(withTheme(
    withStyles(styles)(PayrollcycleMasterPanel)
))));