import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {Button, Fab, Grid, Paper, Divider, Typography} from "@material-ui/core";
import { TextInput, FormattedMessage } from "@openimis/fe-core";
import { saveBank } from "../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SaveAlt, Save } from "@material-ui/icons";



// const styles = theme => ({
//     page: theme.page,
// });

const styles = theme => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%"
    },
});


class AddBank extends Component {

    state = {
        edited: {}
    }

    save = e => {
        this.props.saveBank(this.state.edited, `Creating ${this.state.edited.bankName},`)
        
    }

    updateAttribute = (k, v) => {
        this.setState((state) => ({
            edited: { ...state.edited, [k]: v } 
        }))
    }


    render() {
        const { classes, title = "Banks.title", titleParams = { label: "" }, } = this.props;
        const { edited } = this.state;
        
        return (
            <div className={classes.page}>
                <Grid container>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                        <Grid container className={classes.tableTitle}>
                            <Grid item xs={3} className={classes.tableTitle}>
                                <Typography>
                                    <FormattedMessage module="banks" id={title} values={titleParams} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container className={classes.item}>
                            <Grid item xs={4} className={classes.item}>
                                <TextInput
                                    module="Banks" label="AddBankForm.bankName"
                                    value={edited.bankName}
                                    required={true}
                                    onChange={v => this.updateAttribute("bankName", v)}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.item}>
                                <TextInput
                                    module="Banks" label="AddBankForm.bankEmail"
                                    value={edited.banbankEmail}
                                    required={true}
                                    onChange={v => this.updateAttribute("bankEmail", v)}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.item}>
                                <TextInput
                                    module="Banks" label="AddBankForm.bankAddress"
                                    value={edited.bankAddress}
                                    required={true}
                                    onChange={v => this.updateAttribute("bankAddress", v)}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.item}>
                                <TextInput
                                    module="Banks" label="AddBankForm.bankSwitchCode"
                                    value={edited.bankSwitchCode}
                                    required={true}
                                    onChange={v => this.updateAttribute("bankSwitchCode", v)}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.item}>
                                <TextInput
                                    module="Banks" label="AddBankForm.bankContactDetail"
                                    value={edited.bankContactDetail}
                                    required={true}
                                    onChange={v => this.updateAttribute("bankContactDetail", v)}
                                />
                            </Grid>
                        </Grid>
                        {/* <Button onClick={this.save}>SAVE</Button> */}
                        <Fab color="primary" onClick={this.save}>
						    <Save />
					    </Fab>
                        </Paper>
                    </Grid>
                </Grid>
                <br/>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ saveBank }, dispatch);
};

export default withTheme(withStyles(styles)(connect(null, mapDispatchToProps)(AddBank)));