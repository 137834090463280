import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Fab, Grid, Paper, Typography, Divider  } from "@material-ui/core";
import { journalize, PublishedComponent, FormattedMessage, TextInput } from "@openimis/fe-core";
import { updatePrograms } from "../actions";
import { SaveAlt, Save } from "@material-ui/icons";

const styles = theme => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%"
    },
});

class InsureeActivePage extends Component {
	
	state = {
		edited: {}
	}
	
    componentDidUpdate(prevPops, prevState, snapshort) {
        if (prevPops.submittingMutation && !this.props.submittingMutation) {
            this.props.journalize(this.props.mutation);
        }
    }
	
	save = e => {
		this.props.updatePrograms(this.state.edited, `Updated Insuree in ${this.state.edited.scheme.name}`)
	}
	
	updateAttribute = (k, v) => {
        this.setState((state) => ({
            edited: { ...state.edited, [k]: v }
        }),
			// e => console.log ("STATE " + JSON.stringify(this.state))
		)
    }

    render() {
        const {
            intl, classes,
            title = "Programs.title", titleParams = { label: "" },
            readOnly = true, insuree_uuid,
            actions
        } = this.props;
        const { edited } = this.state;

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container className={classes.tableTitle}>
                            <Grid item xs={3} className={classes.tableTitle}>
                                <Typography>
                                    <FormattedMessage module="programs" id={title} values={titleParams} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container className={classes.item}>
                            <Grid item xs={6} className={classes.item}>
                                <PublishedComponent
                                    pubRef="insuree.InsureePicker"
                                    value={edited.insuree}
                                    onChange={(v) => this.updateAttribute("insuree", v)}
                                    required={true}
                                />
                            </Grid>
                            {/* <Grid item xs={6} className={classes.item}>
                                <PublishedComponent
                                    pubRef="scheme.DropDownSchemesPicker"
                                    value={edited.scheme}
                                    onChange={(v) => this.updateAttribute("scheme", v)}
                                    required={true}
                                />
                            </Grid> */}
                            {/* <Grid item xs={6} className={classes.item}>
                                <PublishedComponent
                                    pubRef="payroll.DropDownPayrollCyclePicker"
                                    value={edited.payrollcycle}
                                    onChange={(v) => this.updateAttribute("payrollcycle", v)}
                                    required={true}
                                />
                            </Grid> */}
                            <Grid item xs={6} className={classes.item}>
                                <PublishedComponent
                                    pubRef="scheme.SchemePicker"
                                    value={edited.name}
                                    onChange={(v) => this.updateAttribute("scheme", v)}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={3} className={classes.item}>
                                <PublishedComponent 
							                    pubRef="core.DatePicker"
							                    label="schemeForm.enrollmentDate"
							                    value={edited.enrollmentDate}
							                    required={true}
                                  onChange={v => this.updateAttribute("enrollmentDate", v)}
						                    />
                            </Grid>
                            <Grid item xs={3} className={classes.item}>
                                <PublishedComponent 
							                    pubRef="core.DatePicker"
							                    label="schemeForm.startDate"
							                    value={edited.startDate}
							                    required={true}
                                  onChange={v => this.updateAttribute("startDate", v)}
						                    />
                            </Grid>
                            <Grid item xs={3} className={classes.item}>
                                <PublishedComponent 
							                    pubRef="core.DatePicker"
							                    label="schemeForm.expiryDate"
							                    value={edited.expiryDate}
							                    required={true}
                                  onChange={v => this.updateAttribute("expiryDate", v)}
						                    />
                            </Grid>
                            <Grid item xs={3} className={classes.item}>
                                <PublishedComponent
                                    pubRef="scheme.InsureeActivePicker"
                                    value={edited.name}
                                    onChange={(v) => this.updateAttribute("status", v)}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={3} className={classes.item}>
                                <TextInput
							        module="scheme" 
                                    label="schemeForm.amount"
							        value={edited.expAmount}
							        required={false}
                                    onChange={v => this.updateAttribute("expAmount", v)}
						        />
                            </Grid>
                        </Grid>
                    </Paper>
                    <div className={classes.fab} variant="contained" style={{display: 'flex', justifyContent: 'right'}}>
					    <Fab color="primary" onClick={this.save}>
						    <Save />
					    </Fab>
                    </div>
                </Grid>
            </Grid>
        );
    }
}


const mapStateToProps = (state, props) => ({
    insuree_uuid: props.match.params.insuree_uuid,
    submittingMutation: state.programs.submittingMutation,
    mutation: state.programs.mutation,
});

const mapDispatchToProps = dispatch => {
	return bindActionCreators({updatePrograms, journalize}, dispatch)
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InsureeActivePage)));
