import React, { Component } from "react";
import { connect } from "react-redux";
import { Edit as EditIcon } from "@material-ui/icons";
import { historyPush, withModulesManager, withHistory } from "@openimis/fe-core";
import PayrollcyclePage from "../pages/PayrollcyclePage";


class PayrollcycleOverviewPage extends Component {
    render() {
        const { history, modulesManager, payrollcycle_uuid } = this.props;
        var actions = [{
            doIt: e => historyPush(modulesManager, history, "payroll.route.payrollcycle", [payrollcycle_uuid]),
            icon: <EditIcon />,
            onlyIfDirty: false
        }]
        return <PayrollcyclePage {...this.props} readOnly={true} overview={true} actions={actions} />
    }
}

const mapStateToProps = (state, props) => ({
    payrollcycle_uuid: props.match.params.payrollcycle_uuid,
})

export default withHistory(withModulesManager(connect(mapStateToProps)(PayrollcycleOverviewPage)));