import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from 'react-intl';
import {historyPush, formatMessageWithValues, formatMessage, withModulesManager, withHistory, ProgressOrError, Table, withTooltip, Searcher} from "@openimis/fe-core";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { fetchPayrollcycles } from "../actions";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { RIGHT_PAYROLL_ADD } from "../constants";
import PayrollCycleList from "../components/PayrollCycleList";

const styles = theme => ({
    page: theme.page,
    fab: theme.fab

});

class PayrollcyclesPage extends Component {
//     //Initialize state
//     state={
//         page : 0,
//         pageSize : 10,
//         afterCursor: null,
//         beforeCursor: null,
//     }

//    //this method is binding to PayrollcyclesPage class and it is executed every time this page is called
//    componentDidMount(){
//        //calls the query method to fetch payroll
//        this.query();
//    }

//    //Define some parameters and pass it while fetching payroll from actions
//    query = () =>{
//        let prams = [];
//        prams.push(`first: ${this.state.pageSize}`)
//        if(!!this.state.afterCursor){
//         prams.push(`after: "${this.state.afterCursor}"`)
//        }
//        if(!!this.state.beforeCursor){
//         prams.push(`before: "${this.state.beforeCursor}"`)
//        }
//         prams.push(`orderBy: ["scheme"]`);
//        this.props.fetchPayrollcycles(prams,this.props.onlyGenerated);
//    }

   onRowDoubleClick= (row) =>{
       console.log(row);
   }
   

    onDoubleClick = (p, newTab = false) => {
        historyPush(this.props.modulesManager, this.props.history, "payroll.route.payrollcycleOverview", [p.uuid], newTab)
    }

    onAdd = () => {
        historyPush(this.props.modulesManager, this.props.history, "payroll.route.payrollcycle");
    }

//    //This function is trigged when the row per page droupdown change which sets the pageSize and recall the query
//    onChangeRowsPerPage = (cnt) =>{
//         this.setState(
//             {pageSize: cnt,
//             page: 0,
//             afterCursor: null,
//             beforeCursor: null,
//             },
//             e => this.query()
//         )
//    }
   
//    //This function is trigged on next and previous page change
//    onChangePage = (page,nbr) =>{
//        if(nbr > this.state.page){
//         this.setState((state,props) => (
//             {
//                 page: state.page + 1,
//                 beforeCursor: null,
//                 afterCursor: props.payrollcyclesPageInfo.endCursor,
//             }),
//             e => this.query()
//         )
//        }else if(nbr < this.state.page){
//         this.setState((state,props) => (
//             {
//                 page: state.page - 1,
//                 beforeCursor: props.payrollcyclesPageInfo.startCursor,
//                 afterCursor: null,
//             }),
//             e => this.query()
//         )
//        }
        
//     }

    render() {
        const { intl, classes, rights } = this.props;

        // const {intl, classes, rights, fetchingPayrollcycles,fetchedPayrollcycles,errorPayrollcycles,payrollcycles,payrollcyclesPageInfo,onlyGenerated} = this.props;

        // let headers =[
        //     'payroll.payrollcycles.scheme_name',
        //     'payroll.payrollcycles.payrollCycleName',
        //     'payroll.payrollcycles.payrollcycleType',
        //     'payroll.payrollcycles.start',
        //     'payroll.payrollcycles.end',
        // ]

        // let itemFormatters =[
            
        //     e => e.scheme.name,
        //     e => e.payrollCycleName,
        //     e => formatMessage(intl, "payroll", `payrollcycleType.${e.PayrollcycleType}`),
        //     e => formatMessage(intl, "payroll", `payrollcycle.startMonth.${e.startMonth}`) + ' ' + e.startYear,
        //     e => formatMessage(intl, "payroll", `payrollcycle.endMonth.${e.endMonth}`) + ' ' + e.endYear
        // ]


        return (
            
            <div className={classes.page} >
                <PayrollCycleList
                    onDoubleClick={this.onDoubleClick}
                />
                
                {rights.includes(RIGHT_PAYROLL_ADD) &&
                    withTooltip(
                        <div className={classes.fab}>
                            <Fab color="primary" onClick={this.onAdd}>
                                <AddIcon />
                            </Fab>
                        </div>,
                        formatMessage(intl, "payroll", "addNewPayrollcycleTooltip")
                    )
                }
               
            </div >
        )
    }
}

// const mapStateToProps = state => ({
//     rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
//     payrollcycles: state.payroll.payrollcycles,
//     payrollcyclesPageInfo: state.payroll.payrollcyclesPageInfo,
//     fetchingPayrollcycles: state.payroll.fetchingPayrollcycles,
//     fetchedPayrollcycles: state.payroll.fetchedPayrollcycles,
//     errorPayrollcycles: state.payroll.errorPayrollcycles,
// })

const mapStateToProps = state => ({
    rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
})

// const mapDispatchToProps = dispatch => {
//     return bindActionCreators({ fetchPayrollcycles }, dispatch);
// };

// export default withHistory(withModulesManager(connect(mapStateToProps, mapDispatchToProps)(
//     injectIntl(withTheme(withStyles(styles)(PayrollcyclesPage))
//     ))));
export default injectIntl(withTheme(withStyles(styles)
    (withHistory(connect(mapStateToProps)(PayrollcyclesPage)))));