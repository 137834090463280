import { Grid } from "@material-ui/core";
import { PublishedComponent, TextInput, FormattedMessage, ControlledField} from "@openimis/fe-core";
import React from "react";


const CreditPostPayment = (props) => {
  const { values, setValues, readOnly, intl, } = props;

  console.log(values)


  return (
    <Grid container direction="column" spacing={1}>
      <Grid>
        <PublishedComponent
          pubRef="payroll.DropDownPayrollCyclePicker"
          value={values.payrollCycleName}
          label="Payroll Cycle"
          onChange={(payrollCycleName) => setValues({ ...values, payrollCycleName})}
        />
      </Grid>
      {/* <Grid>
      <PublishedComponent
              pubRef="location.DetailedLocation"
              withNull={true}
              readOnly={readOnly}
              required
              value={!values ? null : values.location}
              // onChange={(v) => this.updateAttribute("location", v)}
              onChange={(location) => setValues({ ...values, location })}
              filterLabels={false}
              width={200}
            />
      </Grid> */}
      {/* <Grid item>
        <PublishedComponent
          pubRef="payroll.CyclePicker"
          value={values.payrollCycleName}
          label="Pauroll"
          onChange={(payrollCycleName) => setValues({ ...values, payrollCycleName })}
        />
      </Grid> */}
    </Grid>

   
  );
};

export default CreditPostPayment;
