import { Grid } from "@material-ui/core";
import { PublishedComponent, TextInput, Contributions, formatMessage } from "@openimis/fe-core";
import React from "react";
import { injectIntl } from 'react-intl';

const PotentialBeneficiaryList = (props) => {
  const { values, setValues, edited, readOnly, intl  } = props;
   console.log(values)
   
  return (
    <Grid container direction="column" spacing={1}>
      {/* <Grid item>
        <TextInput
          value={values.name}
          module="Programs"
          label="Programs Name"
          onChange={(name) => setValues({ ...values, name })}
        />
      </Grid> */}
       <Grid item>
        <PublishedComponent
          pubRef="scheme.DropDownSchemesPicker"
          value={values.name}
          label="Programs"
          onChange={(name) => setValues({ ...values, name })}
        />
      </Grid>
    </Grid>

  );

};



export default PotentialBeneficiaryList;
