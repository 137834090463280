import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import _ from "lodash";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {withModulesManager,
  TextInput, formatMessage,withTooltip
} from "@openimis/fe-core";
import exportFromJSON from 'export-from-json'  

import {
    Tooltip,
    IconButton
} from "@material-ui/core";
import { GetApp as ExportIcon} from '@material-ui/icons';

const styles = theme => ({
  
});

class Exporter extends Component {  
  
  ExportToExcel = () => {  
    const {data, fileName, exportType} = this.props;
    exportFromJSON({ data, fileName, exportType })  
  }  
  
  render() {  
    const {data, fileName, exportType} = this.props;
    return ( 
      <Fragment>
        <Tooltip title={formatMessage(this.props.intl, "export", "export")}>
              <IconButton onClick={this.ExportToExcel}><ExportIcon /></IconButton>
        </Tooltip>
      </Fragment> 
    );  
  }  
}  

export default withModulesManager(injectIntl(withTheme(
  withStyles(styles)(Exporter)
)));