import React, { Component } from "react";
import { connect } from "react-redux";
import { withModulesManager, withHistory } from "@openimis/fe-core";
import PayrollcyclesPage from "./PayrollcyclesPage";


class PayrollsGenerated extends Component {
    render() {
        return <PayrollcyclesPage onlyGenerated={true} />
    }
}

export default withHistory(withModulesManager(connect(null)(PayrollsGenerated)));