import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Fab,
  Grid,
  Paper,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import {
  TextInput,
  journalize,
  PublishedComponent,
  FormattedMessage,
} from "@openimis/fe-core";
import { createBenefits } from "../actions";

const styles = (theme) => ({
  paper: theme.paper.paper,
  tableTitle: theme.table.title,
  item: theme.paper.item,
  fullHeight: {
    height: "100%",
  },
});

class BenefitsPage extends Component {
  state = {
    state_edited: {},
    state_editedB: {},
  };

  componentDidUpdate(prevPops, prevState, snapshort) {
    if (prevPops.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
    }
  }

  saveBenefits = (e) => {
    this.props.createBenefits(this.state.state_editedB, `Create Benefits`);
  };

  updateAttribute = (k, v) => {
    this.setState((state) => ({
      state_edited: { ...state.state_edited, [k]: v },
    }));
  };

  updateAttributeB = (k, v) => {
    this.setState((state) => ({
      state_editedB: { ...state.state_editedB, [k]: v },
    }));
  };

  render() {
    const {
      intl,
      classes,
      titleone = " Disability",
      titletwo = " Benefits",
      titleParams = { label: "" },
      actions,
    } = this.props;

    const { state_edited, state_editedB } = this.state;
    return (
      <div className={classes.page}>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container className={classes.tableTitle}>
                <Grid item xs={8} className={classes.tableTitle}>
                  <Typography>
                    <FormattedMessage
                      module="benefits"
                      id={titletwo}
                      values={titleParams}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container className={classes.item}>
                <Grid item xs={6} className={classes.item}>
                  <TextInput
                    module="benefits"
                    label="benefits.code"
                    value={state_editedB.code}
                    onChange={(v) => this.updateAttributeB("code", v)}
                    required={false}
                    readOnly={false}
                  />
                </Grid>
                <Grid item xs={6} className={classes.item}>
                  <TextInput
                    module="benefits"
                    label="benefits.disability"
                    value={state_editedB.benefits}
                    onChange={(v) => this.updateAttributeB("benefits", v)}
                    required={false}
                    readOnly={false}
                  />
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    width: "100%",
                  }}
                >
                  <IconButton
                    variant="contained"
                    component="label"
                    color="primary"
                    onClick={this.saveBenefits}
                    fontSize="large"
                  >
                    <Save fontSize="large" />
                  </IconButton>
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  submittingMutation: state.benefits.submittingMutation,
  mutation: state.benefits.mutation,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createBenefits, journalize }, dispatch);
};

export default withTheme(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BenefitsPage))
);
