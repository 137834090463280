import React, { Component } from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { MONTHS } from "../constants";

class MonthPicker extends Component {

    render() {
        const { intl, module = "payroll", label = "month", ...others } = this.props;
        return <ConstantBasedPicker
            module="payroll"
            label={label}
            constants={MONTHS}
            {...this.props}
        />
    }
}

export default MonthPicker;