import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Fab,
  Grid,
  Paper,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import {
  TextInput,
  journalize,
  PublishedComponent,
  FormattedMessage,
  Table,
} from "@openimis/fe-core";
import AddDisability from "../components/AddDisability";
import { createDisability, fetchDisability } from "../actions";

const styles = (theme) => ({
  paper: theme.paper.paper,
  tableTitle: theme.table.title,
  item: theme.paper.item,
  fullHeight: {
    height: "100%",
  },
});

class DisabilityPage extends Component {
  state = {
    state_edited: {},
    state_editedB: {},
  };

  componentDidMount() {
    this.props.fetchDisability();
  }

  componentDidUpdate(prevPops, prevState, snapshort) {
    if (prevPops.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
    }
  }

  saveDisability = (e) => {
    this.props.createDisability(this.state.state_edited, `Create Disability`);
  };

  updateAttribute = (k, v) => {
    this.setState((state) => ({
      state_edited: { ...state.state_edited, [k]: v },
    }));
  };

  updateAttributeB = (k, v) => {
    this.setState((state) => ({
      state_editedB: { ...state.state_editedB, [k]: v },
    }));
  };

  render() {
    const {
      intl,
      classes,
      titleone = " Disability",
      titletwo = " Benefits",
      titleParams = { label: "" },
      actions,
      disabilities,
    } = this.props;
    console.log("Dis", disabilities);
    let headers = ["Code", "Disability"];
    let itemFormatters = [(d) => d.code, (d) => d.disability];
    return (
      <div className={classes.page}>
        <AddDisability />
        <div className={classes.paper}>
          <Table
            module="benefits"
            header="Disabilities"
            headers={headers}
            items={disabilities}
            itemFormatters={itemFormatters}
            size={"small"}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  submittingMutation: state.benefits.submittingMutation,
  mutation: state.benefits.mutation,
  disabilities: state.benefits.disabilities,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { createDisability, journalize, fetchDisability },
    dispatch
  );
};

export default withTheme(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(DisabilityPage)
  )
);
