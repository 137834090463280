import React, { Component } from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { PAYROLLCYCLE_TYPES } from "../constants";

class PayrollcycleTypePicker extends Component {

    render() {
        return <ConstantBasedPicker
            module="payroll"
            label="payrollcycle.type"
            constants={PAYROLLCYCLE_TYPES}
            {...this.props}
        />
    }
}

export default PayrollcycleTypePicker;