import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Contributions, useUserQuery } from "@openimis/fe-core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: theme.page,
}));

const HomePageContainer = (props) => {
  const { user } = useUserQuery();
  const classes = useStyles();

  if (!user) {
    return null;
  }

  return <iframe src="http://20.127.163.30:3000/public/dashboard/919d8780-1760-46c7-a356-3a1e1dc7016f" frameborder="0" width="100%" height="1280" allowtransparency ></iframe>

};

export default HomePageContainer;
