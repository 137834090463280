import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { Dialog, Button, DialogActions, DialogContent } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { Router } from "react-router-dom";
import { fetchSchemes} from "../actions";
import {
    formatMessage, formatMessageWithValues, Contributions, Error, ProgressOrError,
    withModulesManager, withHistory
} from "@openimis/fe-core";

const   SCHEME_ENQUIRY_DIALOG_CONTRIBUTION_KEY = "scheme.EnquiryDialog";

const styles = theme => ({
});

class EnquiryDialog extends Component {

    schemeChanged = () => !!this.props.scheme && (this.props.scheme.code !== this.props.code)

    escFunction = event => {
        if (event.keyCode === 27) {
            this.props.onClose();
        }
    }
    componentDidMount() {
        if (!this.props.open) return;
        document.addEventListener("keydown", this.escFunction, false);
        if (this.schemeChanged()) {
            this.props.fetchSchemes(this.props.modulesManager, this.props.code);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.open) return;
        if (this.schemeChanged() || (prevProps.code !== this.props.code)) {
            this.props.fetchSchemes(this.props.modulesManager, this.props.code);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    render() {
        const { intl, history, fetching, fetched, scheme, error, onClose } = this.props;
        return (
            <Dialog maxWidth="lg" fullWidth={true} open={this.props.open}>
                <DialogContent>
                    <ProgressOrError progress={fetching} error={error} />
                    {!!fetched && !scheme && (
                        <Error error={
                            {
                                code: formatMessage(intl, 'scheme', 'notFound'),
                                detail: formatMessageWithValues(intl, 'scheme', 'codeNotFound', { code: this.props.code })
                            }
                        } />
                    )}
                    {!fetching && !!scheme && (
                        <Fragment>
                            <Router history={history}>
                                <Contributions contributionKey={SCHEME_ENQUIRY_DIALOG_CONTRIBUTION_KEY} scheme={scheme} />
                            </Router>
                        </Fragment>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {formatMessage(intl, 'scheme', 'close')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    fetching: state.programs.fetchingSchemes,
    fetched: state.programs.fetchedSchemes,
    schemes: state.programs.schemes,
    error: state.programs.errorSchemes
});

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ fetchSchemes }, dispatch);
};

export default withModulesManager(withHistory(connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withTheme(
        withStyles(styles)(EnquiryDialog)
    ))))
);