import React,{ Component } from "react";
import { injectIntl } from 'react-intl';
import { Keyboard, ScreenShare, Add } from "@material-ui/icons";
import { formatMessage, MainMenuContribution, withModulesManager } from "@openimis/fe-core";


class DiafMainMenu extends Component {
    render() {
        const { intl } = this.props;
        let entries = [];
        // entries.push({
        //     text: formatMessage(intl, "DiaForm", "menu.my_entities"),
        //     icon: <Keyboard />,
        //     route: "/diaf/my_entities"
        // });
        // entries.push({
        //     text: formatMessage(intl, "dia_form","menu.add_diaf" ),
        //     icon: <Add />,
        //     route: "/diaf/add_diaf"
        // })

        if (!entries.length) return null;
        return (
            <MainMenuContribution
                {...this.props}
                header= {formatMessage(intl, "DiaForm", "mainMenu")}
                icon={<ScreenShare />}
                entries={entries}
            />
        );
    }
}

export default withModulesManager(injectIntl(DiafMainMenu));