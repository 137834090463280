import { Grid } from "@material-ui/core";
import { PublishedComponent, TextInput } from "@openimis/fe-core";
import React from "react";

const ConsolidatedRequestFund = (props) => {
  const { values, setValues, readOnly } = props;

  console.log(values)

  return (
    <Grid container direction="column" spacing={1}>
      {/* <Grid item>
        <TextInput
          value={values.payrollCycleName}
          module="Payroll"
          label="Payroll Cycle"
          onChange={(payrollCycleName) => setValues({ ...values, payrollCycleName })}
        />
      </Grid> */}
      <Grid>
        <PublishedComponent
          pubRef="payroll.DropDownPayrollCyclePicker"
          value={values.payrollCycleName}
          label="Payroll Cycle"
          onChange={(payrollCycleName) => setValues({ ...values, payrollCycleName})}
        />
      </Grid>
      {/* <Grid item>
        <PublishedComponent
          pubRef="payroll.CyclePicker"
          value={values.payrollCycleName}
          label="Pauroll"
          onChange={(payrollCycleName) => setValues({ ...values, payrollCycleName })}
        />
      </Grid> */}
    </Grid>

   
  );
};

export default ConsolidatedRequestFund;
