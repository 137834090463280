import React, { Component, Fragment } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from 'react-intl';
import {
    Paper,
    Grid,
    FormControlLabel,
    Checkbox,
    Typography,
    Divider,
    Tooltip,
    IconButton
} from "@material-ui/core";
import { People as PeopleIcon } from '@material-ui/icons';
import {
    historyPush, withHistory, withModulesManager,
    TextInput, formatMessage, PublishedComponent, FormattedMessage, FormPanel, YearPicker
} from "@openimis/fe-core";
import { payrollLabel } from "../../utils";

const styles = theme => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%"
    },
});

class PaymentDetailsMasterPanel extends FormPanel {
    render() {
        const {
            intl, classes, edited,
            title = "payroll.paymentDetails.title", titleParams = { label: "" },
            readOnly = false,
            actions
        } = this.props;
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container className={classes.item}>
                        < Grid container className={classes.tableTitle}>
                            <Grid item xs={3} className={classes.tableTitle}>
                                    <Typography>
                                        <FormattedMessage module="payroll" id={title} values={titleParams} />
                                    </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                    <Grid container justify="flex-end">
                                        {!!actions && (actions.map((a, idx) => {
                                            return (
                                                <Grid item key={`form-action-${idx}`} className={classes.paperHeaderAction}>
                                                    {withTooltip(a.button, a.tooltip)}
                                                </Grid>
                                            )
                                        }))}
                                    </Grid>
                                </Grid>
                        </Grid>
                        <Divider />
                        <Grid item xs={3} className={classes.item}>
                            <TextInput
                                module="payroll"
                                label="paymentDetails.receiverName"
                                readOnly={false}
                                required={true}
                                value={edited.receiverName}
                                onChange={v => this.updateAttribute('receiverName', v)}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <PublishedComponent pubRef="insuree.IdentificationTypePicker"
                                module="insuree"
                                value={!!edited && !!edited.identificationType ? edited.identificationType.code : null}
                                readOnly={false}
                                withNull={true}
                                nullLabel={formatMessage(intl, "insuree", "IdentificationType.none")}
                                onChange={v => this.updateAttribute('typeOfId', { code: v })}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <TextInput
                                module="payroll"
                                label="paymentDetails.identificationNumber"
                                readOnly={false}
                                required={true}
                                value={edited.identificationNumber}
                                onChange={v => this.updateAttribute('identificationNumber', v)}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <TextInput
                                module="payroll"
                                label="paymentDetails.receiverPhoneNumber"
                                readOnly={false}
                                required={true}
                                value={edited.receiverPhoneNumber}
                                onChange={v => this.updateAttribute('receiverPhoneNumber', v)}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <TextInput
                                module="payroll"
                                label="paymentDetails.transactionNumber"
                                readOnly={false}
                                required={true}
                                value={edited.transactionNumber}
                                onChange={v => this.updateAttribute('transactionNumber', v)}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <TextInput
                                module="payroll"
                                label="paymentDetails.receivedAmount"
                                readOnly={false}
                                required={true}
                                value={edited.receivedAmount}
                                onChange={v => this.updateAttribute('receivedAmount', v)}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <TextInput
                                module="payroll"
                                label="paymentDetails.receiptNumber"
                                readOnly={false}
                                required={true}
                                value={edited.receiptNumber}
                                onChange={v => this.updateAttribute('receiptNumber', v)}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <PublishedComponent pubRef="payroll.TypeOfPaymentPicker"
                                module="payroll"
                                value={!!edited && !!edited.typeOfPayment ? edited.typeOfPayment: null}
                                withNull={true}
                                nullLabel={formatMessage(intl, "payroll", "typeOfPayment.none")}
                                onChange={v => this.updateAttribute('typeOfPayment', v)}
                                readOnly={false}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.item}>
                            <PublishedComponent pubRef="core.DatePicker"
                                value={!!edited ? edited.paymentDate : null}
                                module="payroll"
                                label="paymentDate"
                                readOnly={false}
                                required={true}
                                onChange={v => this.updateAttribute('paymentDate', v)}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.item}>
                            <PublishedComponent
                                pubRef="payroll.PaypointPicker"
                                module="payroll"
                                value={edited.paypoint}
                                onChange={(v, s) => this.updateAttribute("paypoint", v)}
                                required={true}
                                readOnly={false}
                            />
                        </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withModulesManager(withHistory(injectIntl(withTheme(
    withStyles(styles)(PaymentDetailsMasterPanel)
))));