import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {
    formatMessageWithValues, withModulesManager, withHistory, historyPush,
} from "@openimis/fe-core";
import PayrollForm from "../components/Forms/PayrollForm";
import { createPaymentDetails, updatePaymentDetails } from "../actions";
import { RIGHT_PAYROLL_ADD, RIGHT_PAYROLL_EDIT } from "../constants";
import { payrollLabel } from "../utils";

const styles = theme => ({
    page: theme.page,
});

class PayrollPage extends Component {

    add = () => {
        historyPush(this.props.modulesManager, this.props.history, "payroll.route.payroll.paymentdetails", [payroll_uuid])
    }

    save = (payrollcycle) => {
        if (!payrollcycle.uuid) {
            this.props.createPaymentDetails(
                this.props.modulesManager,
                payrollcycle,
                formatMessageWithValues(
                    this.props.intl,
                    "payroll",
                    "createPaymentDetails.mutationLabel",
                    { label: payrollLabel(payrollcycle) }
                )
            );
        } else {
            this.props.updatePaymentDetails(
                this.props.modulesManager,
                payrollcycle,
                formatMessageWithValues(
                    this.props.intl,
                    "payroll",
                    "updatePaymentDetails.mutationLabel",
                    { label: payrollLabel(payrollcycle) }
                )
            );

        }
    }

    render() {
        const { classes, modulesManager, history, rights, payroll_uuid, overview } = this.props;
        // if (!rights.includes(RIGHT_PAYROLL_EDIT)) return null;

        return (
            <div className={classes.page}>
                <PayrollForm
                    overview={overview}
                    payroll_uuid={payroll_uuid}
                    back={e => historyPush(modulesManager, history, "payroll.route.payroll", [payroll_uuid])}
                    add={rights.includes(RIGHT_PAYROLL_ADD) ? this.add : null}
                    save={rights.includes(RIGHT_PAYROLL_EDIT) ? this.save : null}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    payroll_uuid: props.match.params.payroll_uuid,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ createPaymentDetails, updatePaymentDetails }, dispatch);
};

export default withHistory(withModulesManager(connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withTheme(withStyles(styles)(PayrollPage))
    ))));